

export const language = {
    en: {
        common: {
            logout: {
                logout_message: "Are you sure you want to logout:",
                yes: "Yes"
            },
            loading: "Loading",
            dropdown: {
                all: 'All',
                block: 'Block',
                unblock: 'Unblock',
                new: "New",
                rejected: "Rejected",
                pending: "Pending",
                resolved: "Resolved",

            },
            documents: {
                document: "Documents",
                vehicle_registration: "Vehicle Registration",
                vehicle_insurance: "Vehicle Insurance",
                driving_licence: "Driving Licence",
                pending_documents: ' Pending Documents',
                accept: "Accept",
                reject: "Reject"
            },
            footer: {
                copyright: "Copyright",
                all_rights_reserved: "All rights reserved."
            }
        },
        messages: {
            error: {
                error: "Error",
                email_not_exist: "Email does not exist",
                enter_title: "Please enter title",
                enter_message: "Please enter message",
                valid_data: "Please enter valid data",
                rejection_reason: "Please enter reason for rejection",
                provide_reason: "Please provide reason for rejection"

            },
            success: {
                success: "Success",
                user_blocked: "User Blocked",
                driver_blocked: "Driver Blocked",
                driver_accepted: "Driver Accepted",
                driver_unblocked: "Driver Unblocked",
                user_unblocked: "User Unblocked",
                deactivated: "Deactivated",
                activated: "Activated Succesfully!!!",
                complain_resolved: "Complain Resolved.",
                complain_changed_pending: "Complain changed as pending.",
                faq_added: "FAQ successfully added",
                faq_edited: "FAQ successfully edited",
                faq_deleted: "FAQ successfully deleted",
                driver_rejected: "Driver Rejected",
                notification_sent: "Notification Sent",
                request_accepted: "Request accepted successfully",
                request_rejected: "Request rejected successfully",
                document_accepted: "Document accepted.",
                document_rejected: "Document rejected."
            },
            warn: {
                fill_all_field: "Please fill all input fields",
            }
        },
        table_data: {
            title: "Title",
            content: "Content",
            sr_no: 'S.No.',
            name: 'Name',
            email: "Email",
            phone: "Phone",
            rating: 'Rating',
            driver_capital: "DRIVER",
            customer_capital: "CUSTOMER",
            driver_name: 'Driver Name',
            customer_name: "Customer Name",
            driver_rating: 'Driver Rating',
            address: 'Address',
            tag: 'Tag',
            start_address: "Start Address",
            end_address: "End Address",
            ride_id: "Ride Id",
            sent_by: "Sent By",
            message: "Message",
            date: "Date",
            time: 'Time',
            distance: "Distance",
            status: 'Status',
            actions: 'Actions',
            view_capital: 'VIEW',
            block_capital: "BLOCK",
            unblock_capital: "UNBLOCK",
            edit: "Edit",
            back: "Back",
            delete: 'Delete',
            no_record_found: 'No record found',
            no_rows_found: "No rows Found",
            no_data_found: "No Data Found",
            no_complaints_found: "No Complaints Found",
            type: "Type",
            base_fee: "Base Fee",
            price_per_km: "Price per KM",
            read_more: "Read More",
            price_per_min: "Price per Min",
            completed: 'Completed',
            ongoing: "Ongoing",
            ride_cancel_by_user: "Ride cancelled by user",
            ride_cancel_by_driver: "Ride cancelled by driver",
            no_driver_found: 'No Driver Found',
            ride_expired: 'Ride Expired',
            date_time: "Date & Time",
            fair: "Fair",
            commission: "Commission",
            ride_charge: 'Ride Charge',
            cancelation_harge: "Cancelation Charge",
            new: "New",
            update: "Update",
            cancel: "Cancel",
            add_page: "Add Page",
            questions: "Questions",
            answers: "Answers",
            join_on: 'Join On',
            cash_enable: "Cash Enable",
            wallet_amount: 'Wallet Amount'
        },
        header: {
            admin_panel: 'Admin Panel'
        },
        menu_bar: {
            dashboard: 'Dashboard',
            customers: 'Customers',
            drivers: 'Drivers',
            driver_requests: "Driver Requests",
            rides: 'Rides',
            vehicles: 'Vehicles',
            deleted_customers: 'Deleted Customers',
            deleted_drivers: 'Deleted Drivers',
            content: 'Content',
            complaints: 'Complaints',
            notifications: 'Notifications',
            contact_us: 'Contact Us',
            faqs: 'FAQs',
            logout: 'Logout',
        },
        dashboard: {
            customers: 'Customers',
            drivers: 'Drivers',
            driver_requested: "Driver Requested",
            rides: 'Rides',
            contents: 'Contents',
            unresolved_messages: 'Unresolved Messages',
            unresolved_complaints: 'Unresolved Complaints'

        },
        customer: {
            customers: 'Customers',
            placeholder: 'Search customer by email and phone number',
            customer_details: {
                customer_details: "Customer Details",
                personal_info: "Personal Info",
                name: 'Name',
                email: 'Email',
                phone: "Phone",
                reviews_rating: 'Reviews & Rating',
                address: 'Address',
                ride_details: 'Ride Details',
                reason_of_deletion: "Reason of deletion",
            },
        },
        drivers: {
            drivers: 'Drivers',
            placeholder: 'Search name, email and phone number',
            "wallet": {
                "edit_wallet_amount": "Edit Wallet Amount",
                "reason": "Reason",
                "save": "Save",
                "placeholder": "Reason",
                "decrease": "Decrease",
                "increase": "Increase",
                "amount": "Amount",
                "greater_than": "Please enter a value greater than",
                "less_than": "Please enter a value less than",
                "enter_amount": "Please enter amount",
                "valid_reason": "Please enter a valid reason"

            },
            driver_info: {
                driver_infos: "Driver Info",
                driver_details: "Driver Details",
                personal_info: "Driver Info",
                name: 'Name',
                email: 'Email',
                phone: "Phone",
                bank_account: "Bank Account Number / IBAN",
                reviews_rating: 'Reviews & Rating',
                address: 'Address',
                rating: 'Rating',
                status: 'Status',
                experience: "Experience",
                car_name: "Car Name",
                car_number: "Car Number",
                ride_details: 'Ride Details',
                no_driver_found: "No Driver Found",
                reason_of_deletion: "Reason of deletion",
            },

        },
        driver_request: {
            driver_requests: "Driver Requests",
            placeholder: "Search name, email and phone number",
            driver_request_info: {
                driver_request_details: "Driver Request Details",
                accept: "Accept",
                reject: "Reject",
                cancel: "Cancel",
                driver_info: "Driver Info",
                name: 'Name',
                email: 'Email',
                phone: "Phone",
                driver_customer: "Driver/Customer",
                vehicle_name: "Vechicle Name",
                vehicle_number: "Vechicle Number",
                experience: "Experience",
                rejection: "Rejection",
                enter_reason_rejection: "Please enter reason for rejection"
            }

        },
        rides: {
            rides: "Rides",
            placeholder: 'Search by address',
            ride_details: {
                ride_on_map: "Ride On Map",
                ride_details: "Ride Details",
                download_invoice:"Download Invoice"
            },
            rating: {
                customer: {
                    customer_rating: "Customer Rating",
                    name: "Customer Name",
                },
                driver: {
                    driver_rating: "Driver Rating",
                    name: "Driver Name",
                    review: "Reviews",
                }
            }
        },
        vehicle: {
            vehicles: "Vehicles",
            error_messages: {
                commision_required: "Commission is required",
                only_numeric: "Please enter only numeric value",
                price_required: "Price is required",
                cancelation_charge_required: "Cancelation charge is required",
                base_fee_required: "Base fee is required",

            },
            edit_vehicle: {
                edit_vehicle: "Edit Vehicle",
                commission: "Commission",
                price_per_km: "Price Per KM",
                cancelation_charge: "Cancelation Charges",
                price_per_min: "Price Per Min",
                ride_charge: "Ride Charge",
                base_fare: "Base Fair",
                publish: "Publish",

            },

        },
        deleted_customers: {
            deleted_customers: "Deleted Customers",
            deleted_drivers: "Deleted Drivers",
            placeholder: "Search customer,email and phone number",
        },
        content: {
            content: "Content"
        },
        complaints: {
            complaints: "Complaints"
        },
        notifications: {
            notifications: "Notifications",
            send_notifications_to: "Send notifications to",
            all_customers: "All Customers",
            customer: "Cutomer",
            drivers: "Drivers",
            selected_customer_drivers: "Selected customer/drivers",
            select_notification_type: "Select notification type",
            title: "Title",
            message: "Message",
            please_select_user_by_email: "Please Select user by email",
            send: "SEND",
            push: "Push",
            email: "Email"
        },
        contact_us: {
            contact_us: "Contact Us",
            no_content_found: "No Contact Us Message Found",
        },
        faqs: {
            faqs: "FAQs",
            add: {
                add: "ADD",
                add_faq: "Add FAQ",
                edit_faq: "Edit FAQ",
                save_changes: "Save Changes",
                cancel: "Cancel",
                delete_msg: "Are you sure you want to delete FAQ",
                question: "Question",
                answer: "Answer",
                save: "Save",
            }
        }
    },
    de: {
        "common": {
            "loading": "Laden",
            "logout": {
                "logout_message": "Sind Sie sicher, dass Sie sich abmelden möchten:",
                "yes": "Ja"
            },
            "dropdown": {
                "all": "Alle",
                "block": "Blockieren",
                "unblock": "Freigeben",
                "new": "Neu",
                "rejected": "Abgelehnt",
                "pending": "Ausstehend",
                "resolved": "Gelöst"
            },
            "documents": {
                "document": "Dokumente",
                "vehicle_registration": "Fahrzeugzulassung",
                "vehicle_insurance": "Fahrzeugversicherung",
                "driving_licence": "Führerschein",
                "pending_documents": "Ausstehende Dokumente",
                "accept": "Akzeptieren",
                "reject": "Ablehnen"
            },
            "footer": {
                "copyright": "Urheberrecht",
                "all_rights_reserved": "Alle Rechte vorbehalten."
            }
        },
        "messages": {
            "error": {
                "error": "Fehler",
                "email_not_exist": "E-Mail existiert nicht",
                "enter_title": "Bitte Titel eingeben",
                "enter_message": "Bitte Nachricht eingeben",
                "valid_data": "Bitte gültige Daten eingeben",
                "rejection_reason": "Bitte Grund für die Ablehnung angeben",
                "provide_reason": "Bitte Grund für die Ablehnung angeben"
            },
            "success": {
                "success": "Erfolg",
                "user_blocked": "Benutzer blockiert",
                "driver_blocked": "Fahrer blockiert",
                "driver_accepted": "Fahrer akzeptiert",
                "driver_unblocked": "Fahrer entsperrt",
                "user_unblocked": "Benutzer entsperrt",
                "deactivated": "Deaktiviert",
                "activated": "Erfolgreich aktiviert!!!",
                "complain_resolved": "Beschwerde gelöst.",
                "complain_changed_pending": "Beschwerde auf ausstehend geändert.",
                "faq_added": "FAQ erfolgreich hinzugefügt",
                "faq_edited": "FAQ erfolgreich bearbeitet",
                "faq_deleted": "FAQ erfolgreich gelöscht",
                "driver_rejected": "Fahrer abgelehnt",
                "notification_sent": "Benachrichtigung gesendet",
                "request_accepted": "Anfrage erfolgreich akzeptiert",
                "request_rejected": "Anfrage erfolgreich abgelehnt",
                "document_accepted": "Dokument akzeptiert.",
                "document_rejected": "Dokument abgelehnt."
            },
            "warn": {
                "fill_all_field": "Bitte alle Eingabefelder ausfüllen"
            }
        },
        "table_data": {
            "title": "Titel",
            "content": "Inhalt",
            "sr_no": "Nr.",
            "name": "Name",
            "email": "E-Mail",
            "phone": "Telefon",
            "rating": "Bewertung",
            "driver_capital": "FAHRER",
            "customer_capital": "KUNDE",
            "driver_name": "Fahrername",
            "customer_name": "Kundenname",
            "driver_rating": "Fahrerbewertung",
            "address": "Adresse",
            "tag": "Tag",
            "start_address": "Startadresse",
            "end_address": "Zieladresse",
            "ride_id": "Fahrt-ID",
            "sent_by": "Gesendet von",
            "message": "Nachricht",
            "date": "Datum",
            "time": "Zeit",
            "distance": "Entfernung",
            "status": "Status",
            "actions": "Aktionen",
            "view_capital": "ANSICHT",
            "block_capital": "BLOCKIEREN",
            "unblock_capital": "FREIGEBEN",
            "edit": "Bearbeiten",
            "back": "Zurück",
            "delete": "Löschen",
            "no_record_found": "Kein Eintrag gefunden",
            "no_rows_found": "Keine Zeilen gefunden",
            "no_data_found": "Keine Daten gefunden",
            "no_complaints_found": "Keine Beschwerden gefunden",
            "type": "Typ",
            "base_fee": "Grundgebühr",
            "price_per_km": "Preis pro KM",
            "read_more": "Mehr erfahren",
            "price_per_min": "Preis pro Min",
            "completed": "Abgeschlossen",
            "ride_cancel_by_user": "Fahrt vom Nutzer storniert",
            "ride_cancel_by_driver": "Fahrt vom Fahrer storniert",
            "no_driver_found": "Kein Fahrer gefunden",
            "ride_expired": "Fahrt abgelaufen",
            "date_time": "Datum & Uhrzeit",
            "fair": "Fair",
            "commission": "Provision",
            "ride_charge": "Fahrtkosten",
            "cancelation_harge": "Stornogebühr",
            "new": "Neu",
            "update": "Aktualisieren",
            "cancel": "Abbrechen",
            "add_page": "Seite hinzufügen",
            "questions": "Fragen",
            "answers": "Antworten",
            "join_on": "Beitreten am",
            "cash_enable": "Bargeld aktivieren",
            "wallet_amount": 'Wallet-Betrag'
        },
        "header": {
            "admin_panel": "Admin-Bereich"
        },
        "menu_bar": {
            "dashboard": "Dashboard",
            "customers": "Kunden",
            "drivers": "Fahrer",
            "driver_requests": "Fahrer-Anfragen",
            "rides": "Fahrten",
            "vehicles": "Fahrzeuge",
            "deleted_customers": "Gelöschte Kunden",
            "deleted_drivers": "Gelöschte Fahrer",
            "content": "Inhalt",
            "complaints": "Beschwerden",
            "notifications": "Benachrichtigungen",
            "contact_us": "Kontaktieren Sie uns",
            "faqs": "FAQs",
            "logout": "Abmelden"
        },
        "dashboard": {
            "customers": "Kunden",
            "drivers": "Fahrer",
            "driver_requested": "Fahrer angefordert",
            "rides": "Fahrten",
            "contents": "Inhalte",
            "unresolved_messages": "Ungelöste Nachrichten",
            "unresolved_complaints": "Ungelöste Beschwerden"
        },
        "customer": {
            "customers": "Kunden",
            "placeholder": "Kunde nach E-Mail und Telefonnummer suchen",
            "customer_details": {
                "customer_details": "Kundendetails",
                "personal_info": "Persönliche Infos",
                "name": "Name",
                "email": "E-Mail",
                "phone": "Telefon",
                "reviews_rating": "Bewertungen & Bewertung",
                "address": "Adresse",
                "ride_details": "Fahrtdetails",
                "reason_of_deletion": "Löschungsgrund"
            }
        },
        "drivers": {
            "drivers": "Fahrer",
            "wallet": {
                "edit_wallet_amount": "Brieftaschenbetrag bearbeiten",
                "reason": "Grund",
                "save": "Speichern",
                "placeholder": "Grund",
                "decrease": "Verringern",
                "increase": "Erhöhen",
                "amount": "Betrag",
                "greater_than": "Bitte geben Sie einen Wert ein, der größer ist als",
                "less_than": "Bitte geben Sie einen Wert ein, der kleiner ist als",
                "enter_amount": "Bitte geben Sie einen Betrag ein",
                "valid_reason": "Bitte geben Sie einen gültigen Grund ein"
            },
            "placeholder": "Name, E-Mail und Telefonnummer suchen",
            "driver_info": {
                "driver_infos": "Fahrer-Info",
                "driver_details": "Fahrer-Details",
                "personal_info": "Fahrer-Info",
                "name": "Name",
                "email": "E-Mail",
                "phone": "Telefon",
                "bank_account": "Bankkonto Nummer / IBAN",
                "reviews_rating": "Bewertungen & Bewertung",
                "address": "Adresse",
                "rating": "Bewertung",
                "status": "Status",
                "experience": "Erfahrung",
                "car_name": "Auto Name",
                "car_number": "Autonummer",
                "ride_details": "Fahrtdetails",
                "no_driver_found": "Kein Fahrer gefunden",
                "reason_of_deletion": "Löschungsgrund"
            }
        },
        "driver_request": {
            "driver_requests": "Fahrer-Anfragen",
            "placeholder": "Name, E-Mail und Telefonnummer suchen",
            "driver_request_info": {
                "driver_request_details": "Fahrer-Anfrage Details",
                "accept": "Akzeptieren",
                "reject": "Ablehnen",
                "cancel": "Abbrechen",
                "driver_info": "Fahrer-Info",
                "name": "Name",
                "email": "E-Mail",
                "phone": "Telefon",
                "driver_customer": "Fahrer/Kunde",
                "vehicle_name": "Fahrzeug Name",
                "vehicle_number": "Fahrzeug Nummer",
                "experience": "Erfahrung",
                "rejection": "Ablehnung",
                "enter_reason_rejection": "Bitte geben Sie den Grund für die Ablehnung ein"
            }
        },
        "rides": {
            "rides": "Fahrten",
            "placeholder": "Nach Adresse suchen",
            "ride_details": {
                "ride_on_map": "Fahrt auf Karte",
                "ride_details": "Fahrtdetails",
                download_invoice:"Rechnung herunterladen"
            },
            "rating": {
                "customer": {
                    "customer_rating": "Kundenbewertung",
                    "name": "Kundenname"
                },
                "driver": {
                    "driver_rating": "Fahrerbewertung",
                    "name": "Fahrername",
                    "review": "Bewertungen"
                }
            }
        },
        "vehicle": {
            "vehicles": "Fahrzeuge",
            "error_messages": {
                "commision_required": "Provision ist erforderlich",
                "only_numeric": "Bitte nur numerische Werte eingeben",
                "price_required": "Preis ist erforderlich",
                "cancelation_charge_required": "Stornogebühr ist erforderlich",
                "base_fee_required": "Grundgebühr ist erforderlich"
            },
            "edit_vehicle": {
                "edit_vehicle": "Fahrzeug bearbeiten",
                "commission": "Provision",
                "price_per_km": "Preis pro KM",
                "cancelation_charge": "Stornogebühren",
                "price_per_min": "Preis pro Min",
                "ride_charge": "Fahrtkosten",
                "base_fare": "Grundgebühr",
                "publish": "Veröffentlichen"
            }
        },
        "deleted_customers": {
            "deleted_customers": "Gelöschte Kunden",
            "placeholder": "Kunde, E-Mail und Telefonnummer suchen"
        },
        "content": {
            "content": "Inhalt"
        },
        "complaints": {
            "complaints": "Beschwerden"
        },
        "notifications": {
            "notifications": "Benachrichtigungen",
            "send_notifications_to": "Benachrichtigungen senden an",
            "all_customers": "Alle Kunden",
            "customer": "Kunde",
            "drivers": "Fahrer",
            "selected_customer_drivers": "Ausgewählte Kunden/Fahrer",
            "select_notification_type": "Benachrichtigungstyp auswählen",
            "title": "Titel",
            "message": "Nachricht",
            "please_select_user_by_email": "Bitte Benutzer per E-Mail auswählen",
            "send": "SENDEN",
            "push": "Push",
            "email": "E-Mail"
        },
        "contact_us": {
            "contact_us": "Kontaktieren Sie uns",
            "no_content_found": "Keine Nachricht gefunden"
        },
        "faqs": {
            "faqs": "FAQs",
            "add": {
                "add": "HINZUFÜGEN",
                "add_faq": "FAQ hinzufügen",
                "edit_faq": "FAQ bearbeiten",
                "save_changes": "Änderungen speichern",
                "cancel": "Abbrechen",
                "delete_msg": "Sind Sie sicher, dass Sie das FAQ löschen möchten",
                "question": "Frage",
                "answer": "Antwort",
                "save": "Speichern"
            }
        }
    },
    fr: {
        common: {
            loading: "Chargement",
            "logout": {
                "logout_message": "Êtes-vous sûr de vouloir vous déconnecter :",
                "yes": "Oui"
            },
            dropdown: {
                all: 'Tout',
                block: 'Bloquer',
                unblock: 'Débloquer',
                new: "Nouveau",
                rejected: "Rejeté",
                pending: "En attente",
                resolved: "Résolu",
            },
            documents: {
                document: "Documents",
                vehicle_registration: "Immatriculation du véhicule",
                vehicle_insurance: "Assurance du véhicule",
                driving_licence: "Permis de conduire",
                pending_documents: 'Documents en attente',
                accept: "Accepter",
                reject: "Rejeter"
            },
            "footer": {
                "copyright": "Droit d'auteur",
                "all_rights_reserved": "Tous droits réservés."
            }
        },
        "messages": {
            "error": {
                "error": "Erreur",
                "email_not_exist": "L'email n'existe pas",
                "enter_title": "Veuillez entrer le titre",
                "enter_message": "Veuillez entrer le message",
                "valid_data": "Veuillez entrer des données valides",
                "rejection_reason": "Veuillez entrer la raison du rejet",
                "provide_reason": "Veuillez fournir la raison du rejet"
            },
            "success": {
                "success": "Succès",
                "user_blocked": "Utilisateur bloqué",
                "driver_blocked": "Chauffeur bloqué",
                "driver_accepted": "Chauffeur accepté",
                "driver_unblocked": "Chauffeur débloqué",
                "user_unblocked": "Utilisateur débloqué",
                "deactivated": "Désactivé",
                "activated": "Activé avec succès!!!",
                "complain_resolved": "Réclamation résolue.",
                "complain_changed_pending": "Réclamation changée en attente.",
                "faq_added": "FAQ ajoutée avec succès",
                "faq_edited": "FAQ modifiée avec succès",
                "faq_deleted": "FAQ supprimée avec succès",
                "driver_rejected": "Chauffeur rejeté",
                "notification_sent": "Notification envoyée",
                "request_accepted": "Demande acceptée avec succès",
                "request_rejected": "Demande rejetée avec succès",
                "document_accepted": "Document accepté.",
                "document_rejected": "Document rejeté."
            },
            "warn": {
                "fill_all_field": "Veuillez remplir tous les champs"
            }
        },
        table_data: {
            title: "Titre",
            content: "Contenu",
            sr_no: 'N°',
            name: 'Nom',
            email: "E-mail",
            phone: "Téléphone",
            rating: 'Évaluation',
            driver_capital: "CHAUFFEUR",
            customer_capital: "CLIENT",
            driver_name: 'Nom du chauffeur',
            customer_name: "Nom du client",
            driver_rating: 'Évaluation du chauffeur',
            address: 'Adresse',
            tag: 'Étiquette',
            start_address: "Adresse de départ",
            end_address: "Adresse d'arrivée",
            ride_id: "ID de course",
            sent_by: "Envoyé par",
            message: "Message",
            date: "Date",
            time: 'Heure',
            distance: "Distance",
            status: 'Statut',
            actions: 'Actions',
            view_capital: 'VOIR',
            block_capital: "BLOQUER",
            unblock_capital: "DÉBLOQUER",
            edit: "Éditer",
            back: "Retour",
            delete: 'Supprimer',
            no_record_found: 'Aucun enregistrement trouvé',
            no_rows_found: "Aucune ligne trouvée",
            no_data_found: "Aucune donnée trouvée",
            no_complaints_found: "Aucune plainte trouvée",
            type: "Type",
            base_fee: "Frais de base",
            price_per_km: "Prix par KM",
            read_more: "En savoir plus",
            price_per_min: "Prix par minute",
            completed: 'Terminé',
            ride_cancel_by_user: "Course annulée par l'utilisateur",
            ride_cancel_by_driver: "Course annulée par le chauffeur",
            no_driver_found: 'Aucun chauffeur trouvé',
            ride_expired: 'Course expirée',
            date_time: "Date & Heure",
            fair: "Tarif",
            commission: "Commission",
            ride_charge: 'Frais de course',
            cancelation_harge: "Frais d'annulation",
            new: "Nouveau",
            update: "Mettre à jour",
            cancel: "Annuler",
            add_page: "Ajouter une page",
            questions: "Questions",
            answers: "Réponses",
            join_on: "Rejoindre le",
            "cash_enable": "Activer l'argent liquide",
            wallet_amount: 'Montant du portefeuille'
        },
        header: {
            admin_panel: `Panneau d'administration`
        },
        menu_bar: {
            dashboard: 'Tableau de bord',
            customers: 'Clients',
            drivers: 'Chauffeurs',
            driver_requests: "Demandes de chauffeurs",
            rides: 'Courses',
            vehicles: 'Véhicules',
            deleted_customers: 'Clients supprimés',
            deleted_drivers: 'Chauffeurs supprimés',
            content: 'Contenu',
            complaints: 'Plaintes',
            notifications: 'Notifications',
            contact_us: 'Contactez-nous',
            faqs: 'FAQs',
            logout: 'Déconnexion',
        },
        dashboard: {
            customers: 'Clients',
            drivers: 'Chauffeurs',
            driver_requested: "Chauffeur demandé",
            rides: 'Courses',
            contents: 'Contenus',
            unresolved_messages: 'Messages non résolus',
            unresolved_complaints: 'Plaintes non résolues'
        },
        customer: {
            customers: 'Clients',
            placeholder: 'Rechercher un client par e-mail et numéro de téléphone',
            customer_details: {
                customer_details: "Détails du client",
                personal_info: "Infos personnelles",
                name: 'Nom',
                email: 'E-mail',
                phone: "Téléphone",
                reviews_rating: 'Avis & Évaluation',
                address: 'Adresse',
                ride_details: 'Détails de la course',
                reason_of_deletion: "Raison de la suppression",
            },
        },
        drivers: {
            drivers: 'Chauffeurs',
            placeholder: 'Rechercher par nom, e-mail et numéro de téléphone',
            "wallet": {
                "edit_wallet_amount": "Modifier le montant du portefeuille",
                "reason": "Raison",
                "save": "Enregistrer",
                "placeholder": "Raison",
                "decrease": "Diminuer",
                "increase": "Augmenter",
                "amount": "Montant",
                "greater_than": "Veuillez entrer une valeur supérieure à",
                "less_than": "Veuillez entrer une valeur inférieure à",
                "enter_amount": "Veuillez entrer un montant",
                "valid_reason": "Veuillez entrer une raison valide"
            },
            driver_info: {
                driver_infos: "Infos chauffeur",
                driver_details: "Détails du chauffeur",
                personal_info: "Infos personnelles",
                name: 'Nom',
                email: 'E-mail',
                phone: "Téléphone",
                bank_account: "Numéro de compte bancaire / IBAN",
                reviews_rating: 'Avis & Évaluation',
                address: 'Adresse',
                rating: 'Évaluation',
                status: 'Statut',
                experience: "Expérience",
                car_name: "Nom de la voiture",
                car_number: "Numéro de la voiture",
                ride_details: 'Détails de la course',
                no_driver_found: "Aucun chauffeur trouvé",
                reason_of_deletion: "Raison de la suppression",
            },
        },
        driver_request: {
            driver_requests: "Demandes de chauffeurs",
            placeholder: "Rechercher par nom, e-mail et numéro de téléphone",
            driver_request_info: {
                driver_request_details: "Détails de la demande de chauffeur",
                accept: "Accepter",
                reject: "Rejeter",
                cancel: "Annuler",
                driver_info: "Infos chauffeur",
                name: 'Nom',
                email: 'E-mail',
                phone: "Téléphone",
                driver_customer: "Chauffeur/Client",
                vehicle_name: "Nom du véhicule",
                vehicle_number: "Numéro du véhicule",
                experience: "Expérience",
                rejection: "Rejet",
                enter_reason_rejection: "Veuillez entrer la raison du rejet"
            }
        },
        rides: {
            rides: "Courses",
            placeholder: 'Rechercher par adresse',
            ride_details: {
                ride_on_map: "Course sur la carte",
                ride_details: "Détails de la course",
                download_invoice:"Télécharger la facture"
            },
            rating: {
                customer: {
                    customer_rating: "Évaluation du client",
                    name: "Nom du client",
                },
                driver: {
                    driver_rating: "Évaluation du chauffeur",
                    name: "Nom du chauffeur",
                    review: "Avis",
                }
            }
        },
        vehicle: {
            vehicles: "Véhicules",
            error_messages: {
                commision_required: "Commission requise",
                only_numeric: "Veuillez entrer uniquement des valeurs numériques",
                price_required: "Prix requis",
                cancelation_charge_required: "Frais d'annulation requis",
                base_fee_required: "Frais de base requis",
            },
            edit_vehicle: {
                edit_vehicle: "Modifier le véhicule",
                commission: "Commission",
                price_per_km: "Prix par KM",
                cancelation_charge: "Frais d'annulation",
                price_per_min: "Prix par minute",
                ride_charge: "Frais de course",
                base_fare: "Tarif de base",
                publish: "Publier",
            },
        },
        deleted_customers: {
            deleted_customers: "Clients supprimés",
            placeholder: "Rechercher par client, e-mail et numéro de téléphone",
        },
        content: {
            content: "Contenu"
        },
        complaints: {
            complaints: "Plaintes"
        },
        notifications: {
            notifications: "Notifications",
            send_notifications_to: "Envoyer des notifications à",
            all_customers: "Tous les clients",
            customer: "Client",
            drivers: "Chauffeurs",
            selected_customer_drivers: "Clients/chauffeurs sélectionnés",
            select_notification_type: "Sélectionner le type de notification",
            title: "Titre",
            message: "Message",
            please_select_user_by_email: "Veuillez sélectionner l'utilisateur par e-mail",
            send: "ENVOYER",
            push: "Push",
            email: "E-mail"
        },
        contact_us: {
            contact_us: "Contactez-nous",
            no_content_found: "Aucun message de contact trouvé",
        },
        faqs: {
            faqs: "FAQs",
            add: {
                add: "AJOUTER",
                add_faq: "Ajouter FAQ",
                edit_faq: "Modifier FAQ",
                save_changes: "Enregistrer les modifications",
                cancel: "Annuler",
                delete_msg: "Êtes-vous sûr de vouloir supprimer la FAQ",
                question: "Question",
                answer: "Réponse",
                save: "Enregistrer",
            }
        }
    },
    hu: {
        "common": {
            "loading": "Betöltés",
            "logout": {
                "logout_message": "Biztosan kijelentkezik:",
                "yes": "Igen"
            },
            "dropdown": {
                "all": "Mind",
                "block": "Blokkolás",
                "unblock": "Feloldás",
                "new": "Új",
                "rejected": "Elutasítva",
                "pending": "Függőben",
                "resolved": "Megoldva"
            },
            "documents": {
                "document": "Dokumentumok",
                "vehicle_registration": "Járműnyilvántartás",
                "vehicle_insurance": "Járműbiztosítás",
                "driving_licence": "Vezetői engedély",
                "pending_documents": "Függőben lévő dokumentumok",
                "accept": "Elfogadás",
                "reject": "Elutasítás"
            },
            "footer": {
                "copyright": "Szerzői jog",
                "all_rights_reserved": "Minden jog fenntartva."
            }
        },
        "messages": {
            "error": {
                "error": "Hiba",
                "email_not_exist": "Az email nem létezik",
                "enter_title": "Kérjük, adja meg a címet",
                "enter_message": "Kérjük, adja meg az üzenetet",
                "valid_data": "Kérjük, adja meg az érvényes adatokat",
                "rejection_reason": "Kérjük, adja meg az elutasítás okát",
                "provide_reason": "Kérjük, adja meg az elutasítás okát"
            },
            "success": {
                "success": "Siker",
                "user_blocked": "Felhasználó letiltva",
                "driver_blocked": "Vezető letiltva",
                "driver_accepted": "Vezető elfogadva",
                "driver_unblocked": "Vezető feloldva",
                "user_unblocked": "Felhasználó feloldva",
                "deactivated": "Deaktiválva",
                "activated": "Sikeresen aktiválva!!!",
                "complain_resolved": "Panasz megoldva.",
                "complain_changed_pending": "Panasz függőben lévőre változtatva.",
                "faq_added": "GYIK sikeresen hozzáadva",
                "faq_edited": "GYIK sikeresen szerkesztve",
                "faq_deleted": "GYIK sikeresen törölve",
                "driver_rejected": "Vezető elutasítva",
                "notification_sent": "Értesítés elküldve",
                "request_accepted": "Kérelem sikeresen elfogadva",
                "request_rejected": "Kérelem sikeresen elutasítva",
                "document_accepted": "Dokumentum elfogadva.",
                "document_rejected": "Dokumentum elutasítva."
            },
            "warn": {
                "fill_all_field": "Kérjük, töltse ki az összes mezőt"
            }
        },
        "table_data": {
            "title": "Cím",
            "content": "Tartalom",
            "sr_no": "Sorszám",
            "name": "Név",
            "email": "E-mail",
            "phone": "Telefon",
            "rating": "Értékelés",
            "driver_capital": "SOFŐR",
            "customer_capital": "ÜGYFÉL",
            "driver_name": "Sofőr neve",
            "customer_name": "Ügyfél neve",
            "driver_rating": "Sofőr értékelése",
            "address": "Cím",
            "tag": "Címke",
            "start_address": "Kezdő cím",
            "end_address": "Cél cím",
            "ride_id": "Út azonosító",
            "sent_by": "Küldte",
            "message": "Üzenet",
            "date": "Dátum",
            "time": "Idő",
            "distance": "Távolság",
            "status": "Állapot",
            "actions": "Műveletek",
            "view_capital": "MEGTEKINTÉS",
            "block_capital": "BLOKKOLÁS",
            "unblock_capital": "FELBLOKKOLÁS",
            "edit": "Szerkesztés",
            "back": "Vissza",
            "delete": "Törlés",
            "no_record_found": "Nincs találat",
            "no_rows_found": "Nincs találat",
            "no_data_found": "Nincs adat",
            "no_complaints_found": "Nincsenek panaszok",
            "type": "Típus",
            "base_fee": "Alapdíj",
            "price_per_km": "Kilométerenkénti ár",
            "read_more": "Olvass tovább",
            "price_per_min": "Percenkénti ár",
            "completed": "Befejezve",
            "ride_cancel_by_user": "Az utat a felhasználó törölte",
            "ride_cancel_by_driver": "Az utat a sofőr törölte",
            "no_driver_found": "Nem található sofőr",
            "ride_expired": "Az út lejárt",
            "date_time": "Dátum és idő",
            "fair": "Díj",
            "commission": "Jutalék",
            "ride_charge": "Útdíj",
            "cancelation_harge": "Lemondási díj",
            "new": "Új",
            "update": "Frissítés",
            "cancel": "Mégse",
            "add_page": "Oldal hozzáadása",
            "questions": "Kérdések",
            "answers": "Válaszok",
            "join_on": "Csatlakozás ekkor",
            "cash_enable": 'Készpénz engedélyezése',
            wallet_amount: 'Pénztárca összege'
        },
        "header": {
            "admin_panel": "Adminisztrációs felület"
        },
        "menu_bar": {
            "dashboard": "Irányítópult",
            "customers": "Ügyfelek",
            "drivers": "Sofőrök",
            "driver_requests": "Sofőr kérelmek",
            "rides": "Utazások",
            "vehicles": "Járművek",
            "deleted_customers": "Törölt ügyfelek",
            "deleted_drivers": "Törölt sofőrök",
            "content": "Tartalom",
            "complaints": "Panaszok",
            "notifications": "Értesítések",
            "contact_us": "Kapcsolatfelvétel",
            "faqs": "GYIK",
            "logout": "Kijelentkezés"
        },
        "dashboard": {
            "customers": "Ügyfelek",
            "drivers": "Sofőrök",
            "driver_requested": "Sofőr kérelmezve",
            "rides": "Utazások",
            "contents": "Tartalmak",
            "unresolved_messages": "Megoldatlan üzenetek",
            "unresolved_complaints": "Megoldatlan panaszok"
        },
        "customer": {
            "customers": "Ügyfelek",
            "placeholder": "Keresés e-mail és telefonszám alapján",
            "customer_details": {
                "customer_details": "Ügyfél adatai",
                "personal_info": "Személyes adatok",
                "name": "Név",
                "email": "E-mail",
                "phone": "Telefon",
                "reviews_rating": "Értékelések",
                "address": "Cím",
                "ride_details": "Utazás részletei",
                "reason_of_deletion": "Törlés oka"
            }
        },
        "drivers": {
            "drivers": "Sofőrök",
            "wallet": {
                "edit_wallet_amount": "Pénztárca összegének szerkesztése",
                "reason": "Ok",
                "save": "Mentés",
                "placeholder": "Ok",
                "decrease": "Csökkentés",
                "increase": "Növelés",
                "amount": "Összeg",
                "greater_than": "Kérjük, adjon meg egy értéket, amely nagyobb, mint",
                "less_than": "Kérjük, adjon meg egy értéket, amely kisebb, mint",
                "enter_amount": "Kérjük, adjon meg egy összeget",
                "valid_reason": "Kérjük, adjon meg egy érvényes indokot"
            },
            "placeholder": "Keresés név, e-mail és telefonszám alapján",
            "driver_info": {
                "driver_infos": "Sofőr adatai",
                "driver_details": "Sofőr részletei",
                "personal_info": "Sofőr adatai",
                "name": "Név",
                "email": "E-mail",
                "phone": "Telefon",
                "bank_account": "Bankszámlaszám / IBAN",
                "reviews_rating": "Értékelések",
                "address": "Cím",
                "rating": "Értékelés",
                "status": "Állapot",
                "experience": "Tapasztalat",
                "car_name": "Autó neve",
                "car_number": "Autó száma",
                "ride_details": "Utazás részletei",
                "no_driver_found": "Nem található sofőr",
                "reason_of_deletion": "Törlés oka"
            }
        },
        "driver_request": {
            "driver_requests": "Sofőr kérelmek",
            "placeholder": "Keresés név, e-mail és telefonszám alapján",
            "driver_request_info": {
                "driver_request_details": "Sofőr kérelem részletei",
                "accept": "Elfogadás",
                "reject": "Elutasítás",
                "cancel": "Mégse",
                "driver_info": "Sofőr adatai",
                "name": "Név",
                "email": "E-mail",
                "phone": "Telefon",
                "driver_customer": "Sofőr/Ügyfél",
                "vehicle_name": "Jármű neve",
                "vehicle_number": "Jármű száma",
                "experience": "Tapasztalat",
                "rejection": "Elutasítás",
                "enter_reason_rejection": "Adja meg az elutasítás okát"
            }
        },
        "rides": {
            "rides": "Utazások",
            "placeholder": "Keresés cím alapján",
            "ride_details": {
                "ride_on_map": "Utazás a térképen",
                "ride_details": "Utazás részletei",
                download_invoice:"Számla letöltése"
            },
            "rating": {
                "customer": {
                    "customer_rating": "Ügyfél értékelése",
                    "name": "Ügyfél neve"
                },
                "driver": {
                    "driver_rating": "Sofőr értékelése",
                    "name": "Sofőr neve",
                    "review": "Értékelések"
                }
            }
        },
        "vehicle": {
            "vehicles": "Járművek",
            "error_messages": {
                "commision_required": "A jutalék megadása kötelező",
                "only_numeric": "Kérjük, csak számokat adjon meg",
                "price_required": "Az ár megadása kötelező",
                "cancelation_charge_required": "A lemondási díj megadása kötelező",
                "base_fee_required": "Az alapdíj megadása kötelező"
            },
            "edit_vehicle": {
                "edit_vehicle": "Jármű szerkesztése",
                "commission": "Jutalék",
                "price_per_km": "Ár kilométerenként",
                "cancelation_charge": "Lemondási díj",
                "price_per_min": "Ár percenként",
                "ride_charge": "Útdíj",
                "base_fare": "Alapdíj",
                "publish": "Közzététel"
            }
        },
        "deleted_customers": {
            "deleted_customers": "Törölt ügyfelek",
            "placeholder": "Keresés ügyfél, e-mail és telefonszám alapján"
        },
        "content": {
            "content": "Tartalom"
        },
        "complaints": {
            "complaints": "Panaszok"
        },
        "notifications": {
            "notifications": "Értesítések",
            "send_notifications_to": "Értesítések küldése",
            "all_customers": "Minden ügyfél",
            "customer": "Ügyfél",
            "drivers": "Sofőrök",
            "selected_customer_drivers": "Kiválasztott ügyfelek/sofőrök",
            "select_notification_type": "Válassza ki az értesítési típust",
            "title": "Cím",
            "message": "Üzenet",
            "please_select_user_by_email": "Kérjük, válasszon felhasználót e-mail alapján",
            "send": "KÜLDÉS",
            "push": "Push",
            "email": "E-mail"
        },
        "contact_us": {
            "contact_us": "Kapcsolatfelvétel",
            "no_content_found": "Nincs található kapcsolatfelvételi üzenet"
        },
        "faqs": {
            "faqs": "GYIK",
            "add": {
                "add": "HOZZÁADÁS",
                "add_faq": "GYIK hozzáadása",
                "edit_faq": "GYIK szerkesztése",
                "save_changes": "Változások mentése",
                "cancel": "Mégse",
                "delete_msg": "Biztosan törölni szeretné a GYIK-et?",
                "question": "Kérdés",
                "answer": "Válasz",
                "save": "Mentés"
            }
        }
    },
    pl: {
        common: {
            loading: "Ładowanie",
            "logout": {
                "logout_message": "Czy na pewno chcesz się wylogować:",
                "yes": "Tak"
            },
            dropdown: {
                all: 'Wszystko',
                block: 'Zablokuj',
                unblock: 'Odblokuj',
                new: "Nowy",
                rejected: "Odrzucony",
                pending: "Oczekujący",
                resolved: "Rozwiązany",
            },

            documents: {
                document: "Dokumenty",
                vehicle_registration: "Rejestracja pojazdu",
                vehicle_insurance: "Ubezpieczenie pojazdu",
                driving_licence: "Prawo jazdy",
                pending_documents: ' Oczekujące dokumenty',
                accept: "Zaakceptuj",
                reject: "Odrzuć"
            },
            "footer": {
                "copyright": "Prawa autorskie",
                "all_rights_reserved": "Wszelkie prawa zastrzeżone."
            }
        },
        "messages": {
            "error": {
                "error": "Błąd",
                "email_not_exist": "E-mail nie istnieje",
                "enter_title": "Proszę podać tytuł",
                "enter_message": "Proszę wpisać wiadomość",
                "valid_data": "Proszę podać prawidłowe dane",
                "rejection_reason": "Proszę podać powód odrzucenia",
                "provide_reason": "Proszę podać powód odrzucenia"
            },
            "success": {
                "success": "Sukces",
                "user_blocked": "Użytkownik zablokowany",
                "driver_blocked": "Kierowca zablokowany",
                "driver_accepted": "Kierowca zaakceptowany",
                "driver_unblocked": "Kierowca odblokowany",
                "user_unblocked": "Użytkownik odblokowany",
                "deactivated": "Dezaktywowany",
                "activated": "Aktywacja zakończona sukcesem!!!",
                "complain_resolved": "Reklamacja rozwiązana.",
                "complain_changed_pending": "Reklamacja zmieniona na oczekującą.",
                "faq_added": "FAQ pomyślnie dodane",
                "faq_edited": "FAQ pomyślnie edytowane",
                "faq_deleted": "FAQ pomyślnie usunięte",
                "driver_rejected": "Kierowca odrzucony",
                "notification_sent": "Powiadomienie wysłane",
                "request_accepted": "Żądanie pomyślnie zaakceptowane",
                "request_rejected": "Żądanie pomyślnie odrzucone",
                "document_accepted": "Dokument zaakceptowany.",
                "document_rejected": "Dokument odrzucony."
            },
            "warn": {
                "fill_all_field": "Proszę wypełnić wszystkie pola"
            }
        },
        table_data: {
            title: "Tytuł",
            content: "Treść",
            sr_no: 'Nr',
            name: 'Nazwa',
            email: "Email",
            phone: "Telefon",
            rating: 'Ocena',
            driver_capital: "KIEROWCA",
            customer_capital: "KLIENT",
            driver_name: 'Imię kierowcy',
            customer_name: "Imię klienta",
            driver_rating: 'Ocena kierowcy',
            address: 'Adres',
            tag: 'Tag',
            start_address: "Adres początkowy",
            end_address: "Adres końcowy",
            ride_id: "Identyfikator jazdy",
            sent_by: "Wysłane przez",
            message: "Wiadomość",
            date: "Data",
            time: 'Czas',
            distance: "Dystans",
            status: 'Status',
            actions: 'Akcje',
            view_capital: 'ZOBACZ',
            block_capital: "ZABLOKUJ",
            unblock_capital: "ODBLOKUJ",
            edit: "Edytuj",
            back: "Wróć",
            delete: 'Usuń',
            no_record_found: 'Nie znaleziono rekordów',
            no_rows_found: "Nie znaleziono wierszy",
            no_data_found: "Nie znaleziono danych",
            no_complaints_found: "Nie znaleziono skarg",
            type: "Typ",
            base_fee: "Opłata podstawowa",
            price_per_km: "Cena za km",
            read_more: "Czytaj więcej",
            price_per_min: "Cena za minutę",
            completed: 'Zakończone',
            ride_cancel_by_user: "Jazda anulowana przez użytkownika",
            ride_cancel_by_driver: "Jazda anulowana przez kierowcę",
            no_driver_found: 'Nie znaleziono kierowcy',
            ride_expired: 'Jazda wygasła',
            date_time: "Data i czas",
            fair: "Taryfa",
            commission: "Prowizja",
            ride_charge: 'Opłata za jazdę',
            cancelation_harge: "Opłata za anulowanie",
            new: "Nowy",
            update: "Aktualizuj",
            cancel: "Anuluj",
            add_page: "Dodaj stronę",
            questions: "Pytania",
            answers: "Odpowiedzi",
            join_on: "Dołącz w dniu",
            "cash_enable": 'Włącz gotówkę',
            wallet_amount: 'Kwota portfela'
        },
        header: {
            admin_panel: 'Panel administratora'
        },
        menu_bar: {
            dashboard: 'Pulpit',
            customers: 'Klienci',
            drivers: 'Kierowcy',
            driver_requests: "Prośby kierowców",
            rides: 'Jazdy',
            vehicles: 'Pojazdy',
            deleted_customers: 'Usunięci klienci',
            deleted_drivers: 'Usunięci kierowcy',
            content: 'Treści',
            complaints: 'Skargi',
            notifications: 'Powiadomienia',
            contact_us: 'Kontakt',
            faqs: 'FAQs',
            logout: 'Wyloguj się',
        },
        dashboard: {
            customers: 'Klienci',
            drivers: 'Kierowcy',
            driver_requested: "Prośby kierowców",
            rides: 'Jazdy',
            contents: 'Treści',
            unresolved_messages: 'Nierozwiązane wiadomości',
            unresolved_complaints: 'Nierozwiązane skargi'

        },
        customer: {
            customers: 'Klienci',
            placeholder: 'Szukaj klienta po e-mailu i numerze telefonu',
            customer_details: {
                customer_details: "Szczegóły klienta",
                personal_info: "Dane osobowe",
                name: 'Nazwa',
                email: 'Email',
                phone: "Telefon",
                reviews_rating: 'Opinie i oceny',
                address: 'Adres',
                ride_details: 'Szczegóły jazdy',
                reason_of_deletion: "Powód usunięcia",
            },
        },
        drivers: {
            drivers: 'Kierowcy',
            "wallet": {
                "edit_wallet_amount": "Edytuj kwotę portfela",
                "reason": "Powód",
                "save": "Zapisz",
                "placeholder": "Powód",
                "decrease": "Zmniejsz",
                "increase": "Zwiększ",
                "amount": "Kwota",
                "greater_than": "Proszę wprowadzić wartość większą niż",
                "less_than": "Proszę wprowadzić wartość mniejszą niż",
                "enter_amount": "Proszę wprowadzić kwotę",
                "valid_reason": "Proszę podać prawidłowy powód"
            },
            placeholder: 'Szukaj imienia, emaila i numeru telefonu',
            driver_info: {
                driver_infos: "Informacje o kierowcy",
                driver_details: "Szczegóły kierowcy",
                personal_info: "Dane osobowe",
                name: 'Nazwa',
                email: 'Email',
                phone: "Telefon",
                bank_account: "Numer konta bankowego / IBAN",
                reviews_rating: 'Opinie i oceny',
                address: 'Adres',
                rating: 'Ocena',
                status: 'Status',
                experience: "Doświadczenie",
                car_name: "Nazwa samochodu",
                car_number: "Numer samochodu",
                ride_details: 'Szczegóły jazdy',
                no_driver_found: "Nie znaleziono kierowcy",
                reason_of_deletion: "Powód usunięcia",
            },

        },
        driver_request: {
            driver_requests: "Prośby kierowców",
            placeholder: "Szukaj imienia, emaila i numeru telefonu",
            driver_request_info: {
                driver_request_details: "Szczegóły prośby kierowcy",
                accept: "Zaakceptuj",
                reject: "Odrzuć",
                cancel: "Anuluj",
                driver_info: "Informacje o kierowcy",
                name: 'Nazwa',
                email: 'Email',
                phone: "Telefon",
                driver_customer: "Kierowca/Klient",
                vehicle_name: "Nazwa pojazdu",
                vehicle_number: "Numer pojazdu",
                experience: "Doświadczenie",
                rejection: "Odrzucenie",
                enter_reason_rejection: "Podaj powód odrzucenia"
            }

        },
        rides: {
            rides: "Jazdy",
            placeholder: 'Szukaj według adresu',
            ride_details: {
                ride_on_map: "Jazda na mapie",
                ride_details: "Szczegóły jazdy",
                download_invoice:"Pobierz fakturę"
            },
            rating: {
                customer: {
                    customer_rating: "Ocena klienta",
                    name: "Nazwa klienta",
                },
                driver: {
                    driver_rating: "Ocena kierowcy",
                    name: "Nazwa kierowcy",
                    review: "Opinie",
                }
            }
        },
        vehicle: {
            vehicles: "Pojazdy",
            error_messages: {
                commision_required: "Wymagana prowizja",
                only_numeric: "Wprowadź tylko wartość numeryczną",
                price_required: "Cena jest wymagana",
                cancelation_charge_required: "Wymagana opłata za anulowanie",
                base_fee_required: "Wymagana opłata podstawowa",

            },
            edit_vehicle: {
                edit_vehicle: "Edytuj pojazd",
                commission: "Prowizja",
                price_per_km: "Cena za km",
                cancelation_charge: "Opłaty za anulowanie",
                price_per_min: "Cena za minutę",
                ride_charge: "Opłata za jazdę",
                base_fare: "Opłata podstawowa",
                publish: "Opublikuj",

            },

        },
        deleted_customers: {
            deleted_customers: "Usunięci klienci",
            placeholder: "Szukaj klienta, e-maila i numeru telefonu",

        },
        content: {
            content: "Treści"
        },
        complaints: {
            complaints: "Skargi"
        },
        notifications: {
            notifications: "Powiadomienia",
            send_notifications_to: "Wyślij powiadomienia do",
            all_customers: "Wszyscy klienci",
            customer: "Klient",
            drivers: "Kierowcy",
            selected_customer_drivers: "Wybrani klienci/kierowcy",
            select_notification_type: "Wybierz rodzaj powiadomienia",
            title: "Tytuł",
            message: "Wiadomość",
            please_select_user_by_email: "Wybierz użytkownika po e-mailu",
            send: "WYŚLIJ",
            push: "Push",
            email: "Email"
        },
        contact_us: {
            contact_us: "Kontakt",
            no_content_found: "Nie znaleziono wiadomości w Kontakcie",
        },
        faqs: {
            faqs: "FAQs",
            add: {
                add: "DODAJ",
                add_faq: "Dodaj FAQ",
                edit_faq: "Edytuj FAQ",
                save_changes: "Zapisz zmiany",
                cancel: "Anuluj",
                delete_msg: "Czy na pewno chcesz usunąć FAQ",
                question: "Pytanie",
                answer: "Odpowiedź",
                save: "Zapisz",
            }
        }
    },
    sk: {
        common: {
            loading: "Načítavanie",
            "logout": {
                "logout_message": "Naozaj sa chcete odhlásiť:",
                "yes": "Áno"
            },
            dropdown: {
                all: 'Všetky',
                block: 'Blokovať',
                unblock: 'Odblokovať',
                new: "Nové",
                rejected: "Zamietnuté",
                pending: "Čakajúce",
                resolved: "Riešené",
            },

            documents: {
                document: "Dokumenty",
                vehicle_registration: "Registrácia vozidla",
                vehicle_insurance: "Poistenie vozidla",
                driving_licence: "Vodičský preukaz",
                pending_documents: 'Čakajúce dokumenty',
                accept: "Akceptovať",
                reject: "Zamietnuť"
            },
            "footer": {
                "copyright": "Autorské práva",
                "all_rights_reserved": "Všetky práva vyhradené."
            }
        },
        "messages": {
            "error": {
                "error": "Chyba",
                "email_not_exist": "E-mail neexistuje",
                "enter_title": "Prosím, zadajte názov",
                "enter_message": "Prosím, zadajte správu",
                "valid_data": "Prosím, zadajte platné údaje",
                "rejection_reason": "Prosím, zadajte dôvod zamietnutia",
                "provide_reason": "Prosím, zadajte dôvod zamietnutia"
            },
            "success": {
                "success": "Úspech",
                "user_blocked": "Používateľ zablokovaný",
                "driver_blocked": "Vodič zablokovaný",
                "driver_accepted": "Vodič prijatý",
                "driver_unblocked": "Vodič odblokovaný",
                "user_unblocked": "Používateľ odblokovaný",
                "deactivated": "Deaktivovaný",
                "activated": "Úspešne aktivovaný!!!",
                "complain_resolved": "Sťažnosť vyriešená.",
                "complain_changed_pending": "Sťažnosť zmenená na čakajúcu.",
                "faq_added": "FAQ úspešne pridané",
                "faq_edited": "FAQ úspešne upravené",
                "faq_deleted": "FAQ úspešne odstránené",
                "driver_rejected": "Vodič zamietnutý",
                "notification_sent": "Oznámenie odoslané",
                "request_accepted": "Žiadosť úspešne prijatá",
                "request_rejected": "Žiadosť úspešne zamietnutá",
                "document_accepted": "Dokument prijatý.",
                "document_rejected": "Dokument zamietnutý."
            },
            "warn": {
                "fill_all_field": "Vyplňte, prosím, všetky vstupné polia"
            }
        },
        table_data: {
            title: "Názov",
            content: "Obsah",
            sr_no: 'S.č.',
            name: 'Meno',
            email: "Email",
            phone: "Telefón",
            rating: 'Hodnotenie',
            driver_capital: "VODIČ",
            customer_capital: "ZÁKAZNÍK",
            driver_name: 'Meno vodiča',
            customer_name: "Meno zákazníka",
            driver_rating: 'Hodnotenie vodiča',
            address: 'Adresa',
            tag: 'Štítok',
            start_address: "Začiatková adresa",
            end_address: "Konečná adresa",
            ride_id: "ID jazdy",
            sent_by: "Od",
            message: "Správa",
            date: "Dátum",
            time: 'Čas',
            distance: "Vzdialenosť",
            status: 'Stav',
            actions: 'Akcie',
            view_capital: 'ZOBRAZIŤ',
            block_capital: "BLOKOVAŤ",
            unblock_capital: "ODBLOKOVAŤ",
            edit: "Upraviť",
            back: "Späť",
            delete: 'Odstrániť',
            no_record_found: 'Žiadny záznam nenájdený',
            no_rows_found: "Žiadne riadky nenájdené",
            no_data_found: "Žiadne údaje nenájdené",
            no_complaints_found: "Žiadne sťažnosti nenájdené",
            type: "Typ",
            base_fee: "Základný poplatok",
            price_per_km: "Cena za KM",
            read_more: "Prečítať viac",
            price_per_min: "Cena za minútu",
            completed: 'Dokončené',
            ride_cancel_by_user: "Jazda zrušená používateľom",
            ride_cancel_by_driver: "Jazda zrušená vodičom",
            no_driver_found: 'Žiadny vodič nenájdený',
            ride_expired: 'Jazda vypršala',
            date_time: "Dátum & Čas",
            fair: "Sadzba",
            commission: "Provízia",
            ride_charge: 'Poplatok za jazdu',
            cancelation_charge: "Poplatok za zrušenie",
            new: "Nové",
            update: "Aktualizovať",
            cancel: "Zrušiť",
            add_page: "Pridať stránku",
            questions: "Otázky",
            answers: "Odpovede",
            join_on: "Pripojiť sa dňa",
            cash_enable: 'Povoliť hotovosť',
            wallet_amount: '	Suma v peňaženke'
        },
        header: {
            admin_panel: 'Admin Panel'
        },
        menu_bar: {
            dashboard: 'Dashboard',
            customers: 'Zákazníci',
            drivers: 'Vodiči',
            driver_requests: "Žiadosti o vodičov",
            rides: 'Jazdy',
            vehicles: 'Vozidlá',
            deleted_customers: 'Zmazaní zákazníci',
            deleted_drivers: 'Zmazaní vodiči',
            content: 'Obsah',
            complaints: 'Sťažnosti',
            notifications: 'Notifikácie',
            contact_us: 'Kontaktujte nás',
            faqs: 'Často kladené otázky',
            logout: 'Odhlásiť sa',
        },
        dashboard: {
            customers: 'Zákazníci',
            drivers: 'Vodiči',
            driver_requested: "Žiadosť o vodiča",
            rides: 'Jazdy',
            contents: 'Obsahy',
            unresolved_messages: 'Nevyriešené správy',
            unresolved_complaints: 'Nevyriešené sťažnosti'
        },
        customer: {
            customers: 'Zákazníci',
            placeholder: 'Hľadať zákazníka podľa emailu a telefónneho čísla',
            customer_details: {
                customer_details: "Detaily zákazníka",
                personal_info: "Osobné informácie",
                name: 'Meno',
                email: 'Email',
                phone: "Telefón",
                reviews_rating: 'Recenzie & Hodnotenie',
                address: 'Adresa',
                ride_details: 'Detaily jazdy',
                reason_of_deletion: "Dôvod zmazania",
            },
        },
        drivers: {
            drivers: 'Vodiči',
            "wallet": {
                "edit_wallet_amount": "Upraviť sumu peňaženky",
                "reason": "Dôvod",
                "save": "Uložiť",
                "placeholder": "Dôvod",
                "decrease": "Znížiť",
                "increase": "Zvýšiť",
                "amount": "Suma",
                "greater_than": "Zadajte hodnotu väčšiu ako",
                "less_than": "Zadajte hodnotu menšiu ako",
                "enter_amount": "Zadajte sumu",
                "valid_reason": "Zadajte platný dôvod"
            }
            ,
            placeholder: 'Hľadať podľa mena, emailu a telefónneho čísla',
            driver_info: {
                driver_infos: "Informácie o vodičovi",
                driver_details: "Detaily vodiča",
                personal_info: "Osobné informácie",
                name: 'Meno',
                email: 'Email',
                phone: "Telefón",
                bank_account: "Číslo bankového účtu / IBAN",
                reviews_rating: 'Recenzie & Hodnotenie',
                address: 'Adresa',
                rating: 'Hodnotenie',
                status: 'Stav',
                experience: "Skúsenosti",
                car_name: "Názov auta",
                car_number: "Číslo auta",
                ride_details: 'Detaily jazdy',
                no_driver_found: "Žiadny vodič nenájdený",
                reason_of_deletion: "Dôvod zmazania",
            },
        },
        driver_request: {
            driver_requests: "Žiadosti o vodičov",
            placeholder: "Hľadať podľa mena, emailu a telefónneho čísla",
            driver_request_info: {
                driver_request_details: "Detaily žiadosti o vodiča",
                accept: "Akceptovať",
                reject: "Zamietnuť",
                cancel: "Zrušiť",
                driver_info: "Informácie o vodičovi",
                name: 'Meno',
                email: 'Email',
                phone: "Telefón",
                driver_customer: "Vodič/Zákazník",
                vehicle_name: "Názov vozidla",
                vehicle_number: "Číslo vozidla",
                experience: "Skúsenosti",
                rejection: "Zamietnutie",
                enter_reason_rejection: "Zadajte dôvod zamietnutia"
            }
        },
        rides: {
            rides: "Jazdy",
            placeholder: 'Hľadať podľa adresy',
            ride_details: {
                ride_on_map: "Jazda na mape",
                ride_details: "Detaily jazdy",
                download_invoice:"Stiahnuť faktúru"
            },
            rating: {
                customer: {
                    customer_rating: "Hodnotenie zákazníka",
                    name: "Meno zákazníka",
                },
                driver: {
                    driver_rating: "Hodnotenie vodiča",
                    name: "Meno vodiča",
                    review: "Recenzie",
                }
            }
        },
        vehicle: {
            vehicles: "Vozidlá",
            error_messages: {
                commision_required: "Provízia je povinná",
                only_numeric: "Zadajte iba číselné hodnoty",
                price_required: "Cena je povinná",
                cancelation_charge_required: "Poplatok za zrušenie je povinný",
                base_fee_required: "Základný poplatok je povinný",
            },
            edit_vehicle: {
                edit_vehicle: "Upraviť vozidlo",
                commission: "Provízia",
                price_per_km: "Cena za KM",
                cancelation_charge: "Poplatky za zrušenie",
                price_per_min: "Cena za minútu",
                ride_charge: "Poplatok za jazdu",
                base_fare: "Základná sadzba",
                publish: "Zverejniť",
            },
        },
        deleted_customers: {
            deleted_customers: "Zmazaní zákazníci",
            placeholder: "Hľadať zákazníka, email a telefónne číslo",
        },
        content: {
            content: "Obsah"
        },
        complaints: {
            complaints: "Sťažnosti"
        },
        notifications: {
            notifications: "Notifikácie",
            send_notifications_to: "Poslať notifikácie",
            all_customers: "Všetkým zákazníkom",
            customer: "Zákazník",
            drivers: "Vodiči",
            selected_customer_drivers: "Vybraným zákazníkom/vodičom",
            select_notification_type: "Vyberte typ notifikácie",
            title: "Názov",
            message: "Správa",
            please_select_user_by_email: "Vyberte používateľa podľa emailu",
            send: "ODOSLAŤ",
            push: "Push",
            email: "Email"
        },
        contact_us: {
            contact_us: "Kontaktujte nás",
            no_content_found: "Žiadne správy v Kontaktujte nás nenájdené",
        },
        faqs: {
            faqs: "Často kladené otázky",
            add: {
                add: "PRIDAŤ",
                add_faq: "Pridať FAQ",
                edit_faq: "Upraviť FAQ",
                save_changes: "Uložiť zmeny",
                cancel: "Zrušiť",
                delete_msg: "Ste si istý, že chcete odstrániť FAQ",
                question: "Otázka",
                answer: "Odpoveď",
                save: "Uložiť",
            }
        }
    },
    sl: {
        common: {
            loading: "Nalaganje",
            "logout": {
                "logout_message": "Ali ste prepričani, da se želite odjaviti:",
                "yes": "Da"
            },
            dropdown: {
                all: 'Vse',
                block: 'Blokiraj',
                unblock: 'Odkleni',
                new: "Nov",
                rejected: "Zavrnjeno",
                pending: "Čaka",
                resolved: "Rešeno",
            },
            documents: {
                document: "Dokumenti",
                vehicle_registration: "Registracija vozila",
                vehicle_insurance: "Zavarovanje vozila",
                driving_licence: "Vozniško dovoljenje",
                pending_documents: ' Dokumenti v čakanju',
                accept: "Sprejmi",
                reject: "Zavrni"
            },
            "footer": {
                "copyright": "Avtorske pravice",
                "all_rights_reserved": "Vse pravice pridržane."
            }
        },
        "messages": {
            "error": {
                "error": "Napaka",
                "email_not_exist": "E-pošta ne obstaja",
                "enter_title": "Prosim, vnesite naslov",
                "enter_message": "Prosim, vnesite sporočilo",
                "valid_data": "Prosim, vnesite veljavne podatke",
                "rejection_reason": "Prosim, navedite razlog za zavrnitev",
                "provide_reason": "Prosim, navedite razlog za zavrnitev"
            },
            "success": {
                "success": "Uspeh",
                "user_blocked": "Uporabnik blokiran",
                "driver_blocked": "Voznik blokiran",
                "driver_accepted": "Voznik sprejet",
                "driver_unblocked": "Voznik odblokiran",
                "user_unblocked": "Uporabnik odblokiran",
                "deactivated": "Deaktivirano",
                "activated": "Uspešno aktivirano!!!",
                "complain_resolved": "Pritožba rešena.",
                "complain_changed_pending": "Pritožba spremenjena v čakajočo.",
                "faq_added": "Pogosta vprašanja uspešno dodana",
                "faq_edited": "Pogosta vprašanja uspešno urejena",
                "faq_deleted": "Pogosta vprašanja uspešno izbrisana",
                "driver_rejected": "Voznik zavrnjen",
                "notification_sent": "Obvestilo poslano",
                "request_accepted": "Zahteva uspešno sprejeta",
                "request_rejected": "Zahteva uspešno zavrnjena",
                "document_accepted": "Dokument sprejet.",
                "document_rejected": "Dokument zavrnjen."
            },
            "warn": {
                "fill_all_field": "Prosimo, izpolnite vsa polja"
            }
        },
        table_data: {
            title: "Naslov",
            content: "Vsebina",
            sr_no: 'Št.',
            name: 'Ime',
            email: "E-pošta",
            phone: "Telefon",
            rating: 'Ocena',
            driver_capital: "VOZNIK",
            customer_capital: "UPORABNIK",
            driver_name: 'Ime voznika',
            customer_name: "Ime uporabnika",
            driver_rating: 'Ocena voznika',
            address: 'Naslov',
            tag: 'Oznaka',
            start_address: "Začetni naslov",
            end_address: "Koncni naslov",
            ride_id: "ID vožnje",
            sent_by: "Poslano od",
            message: "Sporočilo",
            date: "Datum",
            time: 'Čas',
            distance: "Razdalja",
            status: 'Status',
            actions: 'Dejanja',
            view_capital: 'OGLED',
            block_capital: "BLOKIRAJ",
            unblock_capital: "ODKLENI",
            edit: "Uredi",
            back: "Nazaj",
            delete: 'Izbriši',
            no_record_found: 'Ni najdenih zapisov',
            no_rows_found: "Ni najdenih vrstic",
            no_data_found: "Podatki niso bili najdeni",
            no_complaints_found: "Ni najdenih pritožb",
            type: "Vrsta",
            base_fee: "Osnovna tarifa",
            price_per_km: "Cena na KM",
            read_more: "Preberi več",
            price_per_min: "Cena na min",
            completed: 'Zaključeno',
            ride_cancel_by_user: "Vožnja je bila preklicana s strani uporabnika",
            ride_cancel_by_driver: "Vožnja je bila preklicana s strani voznika",
            no_driver_found: 'Ni najdenega voznika',
            ride_expired: 'Vožnja je potekla',
            date_time: "Datum in čas",
            fair: "Pošteno",
            commission: "Provizija",
            ride_charge: 'Strošek vožnje',
            cancelation_charge: "Strošek preklica",
            new: "Nov",
            update: "Posodobi",
            cancel: "Prekliči",
            add_page: "Dodaj stran",
            questions: "Vprašanja",
            answers: "Odgovori",
            join_on: "Pridruži se na",
            cash_enable: "Omogoči gotovino",
            wallet_amount: 'Znesek v denarnici'
        },
        header: {
            admin_panel: 'Upravni panel'
        },
        menu_bar: {
            dashboard: 'Nadzorna plošča',
            customers: 'Uporabniki',
            drivers: 'Vozniki',
            driver_requests: "Zahteve za voznike",
            rides: 'Vožnje',
            vehicles: 'Vozila',
            deleted_customers: 'Izbrisani uporabniki',
            deleted_drivers: 'Izbrisani vozniki',
            content: 'Vsebina',
            complaints: 'Pritožbe',
            notifications: 'Obvestila',
            contact_us: 'Kontaktirajte nas',
            faqs: 'Pogosto zastavljena vprašanja',
            logout: 'Odjava',
        },
        dashboard: {
            customers: 'Uporabniki',
            drivers: 'Vozniki',
            driver_requested: "Zahtevani vozniki",
            rides: 'Vožnje',
            contents: 'Vsebine',
            unresolved_messages: 'Nezainteresirana sporočila',
            unresolved_complaints: 'Nezainteresirane pritožbe'
        },
        customer: {
            customers: 'Uporabniki',
            placeholder: 'Poiščite uporabnika po e-pošti in številki telefona',
            customer_details: {
                customer_details: "Podrobnosti uporabnika",
                personal_info: "Osebne informacije",
                name: 'Ime',
                email: 'E-pošta',
                phone: "Telefon",
                reviews_rating: 'Ocene in mnenja',
                address: 'Naslov',
                ride_details: 'Podrobnosti vožnje',
                reason_of_deletion: "Razlog za izbris",
            },
        },
        drivers: {
            drivers: 'Vozniki',
            "wallet": {
                "edit_wallet_amount": "Uredi znesek denarnice",
                "reason": "Razlog",
                "save": "Shrani",
                "placeholder": "Razlog",
                "decrease": "Zmanjšaj",
                "increase": "Povečaj",
                "amount": "Znesek",
                "greater_than": "Vnesite vrednost, večjo od",
                "less_than": "Vnesite vrednost, manjšo od",
                "enter_amount": "Vnesite znesek",
                "valid_reason": "Vnesite veljaven razlog"
            },
            placeholder: 'Poiščite ime, e-pošto in številko telefona',
            driver_info: {
                driver_infos: "Informacije o vozniku",
                driver_details: "Podrobnosti o vozniku",
                personal_info: "Osebne informacije",
                name: 'Ime',
                email: 'E-pošta',
                phone: "Telefon",
                bank_account: "Številka bančnega računa / IBAN",
                reviews_rating: 'Ocene in mnenja',
                address: 'Naslov',
                rating: 'Ocena',
                status: 'Status',
                experience: "Izkušnje",
                car_name: "Ime vozila",
                car_number: "Številka vozila",
                ride_details: 'Podrobnosti vožnje',
                no_driver_found: "Ni najdenega voznika",
                reason_of_deletion: "Razlog za izbris",
            },
        },
        driver_request: {
            driver_requests: "Zahteve za voznike",
            placeholder: "Poiščite ime, e-pošto in številko telefona",
            driver_request_info: {
                driver_request_details: "Podrobnosti zahteve za voznika",
                accept: "Sprejmi",
                reject: "Zavrni",
                cancel: "Prekliči",
                driver_info: "Informacije o vozniku",
                name: 'Ime',
                email: 'E-pošta',
                phone: "Telefon",
                driver_customer: "Voznik/Uporabnik",
                vehicle_name: "Ime vozila",
                vehicle_number: "Številka vozila",
                experience: "Izkušnje",
                rejection: "Zavrnitev",
                enter_reason_rejection: "Prosimo vnesite razlog za zavrnitev"
            }
        },
        rides: {
            rides: "Vožnje",
            placeholder: 'Išči po naslovu',
            ride_details: {
                ride_on_map: "Vožnja na zemljevidu",
                ride_details: "Podrobnosti vožnje",
                download_invoice:"Prenesi račun"
            },
            rating: {
                customer: {
                    customer_rating: "Ocena uporabnika",
                    name: "Ime uporabnika",
                },
                driver: {
                    driver_rating: "Ocena voznika",
                    name: "Ime voznika",
                    review: "Mnenja",
                }
            }
        },
        vehicle: {
            vehicles: "Vozila",
            error_messages: {
                commision_required: "Provizija je obvezna",
                only_numeric: "Vnesite samo številčne vrednosti",
                price_required: "Cena je obvezna",
                cancelation_charge_required: "Strošek preklica je obvezen",
                base_fee_required: "Osnovna tarifa je obvezna",
            },
            edit_vehicle: {
                edit_vehicle: "Uredi vozilo",
                commission: "Provizija",
                price_per_km: "Cena na KM",
                cancelation_charge: "Strošek preklica",
                price_per_min: "Cena na min",
                ride_charge: "Strošek vožnje",
                base_fare: "Osnovni strošek",
                publish: "Objavi",
            },
        },
        deleted_customers: {
            deleted_customers: "Izbrisani uporabniki",
            placeholder: "Poiščite uporabnika, e-pošto in številko telefona",
        },
        content: {
            content: "Vsebina"
        },
        complaints: {
            complaints: "Pritožbe"
        },
        notifications: {
            notifications: "Obvestila",
            send_notifications_to: "Pošlji obvestila",
            all_customers: "Vsem uporabnikom",
            customer: "Uporabniku",
            drivers: "Voznikom",
            selected_customer_drivers: "Izbranim uporabnikom/voznikom",
            select_notification_type: "Izberite vrsto obvestila",
            title: "Naslov",
            message: "Sporočilo",
            please_select_user_by_email: "Prosimo, izberite uporabnika po e-pošti",
            send: "POŠLJI",
            push: "Potisni",
            email: "E-pošta"
        },
        contact_us: {
            contact_us: "Kontaktirajte nas",
            no_content_found: "Ni najdenih sporočil Kontaktirajte nas",
        },
        faqs: {
            faqs: "Pogosto zastavljena vprašanja",
            add: {
                add: "DODAJ",
                add_faq: "Dodaj FAQ",
                edit_faq: "Uredi FAQ",
                save_changes: "Shrani spremembe",
                cancel: "Prekliči",
                delete_msg: "Ste prepričani, da želite izbrisati FAQ?",
                question: "Vprašanje",
                answer: "Odgovor",
                save: "Shrani",
            }
        }
    },
    bg: {
        "common": {
            "loading": "Зареждане",
            "logout": {
                "logout_message": "Сигурни ли сте, че искате да излезете:",
                "yes": "Да"
            },
            "dropdown": {
                "all": "Всички",
                "block": "Блокирай",
                "unblock": "Разблокирай",
                "new": "Нов",
                "rejected": "Отхвърлен",
                "pending": "В очакване",
                "resolved": "Решен"
            },
            "documents": {
                "document": "Документи",
                "vehicle_registration": "Регистрация на превозно средство",
                "vehicle_insurance": "Застраховка на превозно средство",
                "driving_licence": "Шофьорска книжка",
                "pending_documents": "Документи в очакване",
                "accept": "Приеми",
                "reject": "Отхвърли"
            },
            "footer": {
                "copyright": "Авторско право",
                "all_rights_reserved": "Всички права запазени."
            }
        },
        "messages": {
            "error": {
                "error": "Грешка",
                "email_not_exist": "Имейлът не съществува",
                "enter_title": "Моля, въведете заглавие",
                "enter_message": "Моля, въведете съобщение",
                "valid_data": "Моля, въведете валидни данни",
                "rejection_reason": "Моля, въведете причина за отхвърляне",
                "provide_reason": "Моля, посочете причина за отхвърляне"
            },
            "success": {
                "success": "Успех",
                "user_blocked": "Потребителят е блокиран",
                "driver_blocked": "Шофьорът е блокиран",
                "driver_accepted": "Шофьорът е приет",
                "driver_unblocked": "Шофьорът е деблокиран",
                "user_unblocked": "Потребителят е деблокиран",
                "deactivated": "Деактивирано",
                "activated": "Успешно активирано!!!",
                "complain_resolved": "Жалбата е разрешена.",
                "complain_changed_pending": "Жалбата е променена в очакване.",
                "faq_added": "ЧЗВ успешно добавени",
                "faq_edited": "ЧЗВ успешно редактирани",
                "faq_deleted": "ЧЗВ успешно изтрити",
                "driver_rejected": "Шофьорът е отхвърлен",
                "notification_sent": "Изпратено известие",
                "request_accepted": "Заявката е приета успешно",
                "request_rejected": "Заявката е отхвърлена успешно",
                "document_accepted": "Документът е приет.",
                "document_rejected": "Документът е отхвърлен."
            },
            "warn": {
                "fill_all_field": "Моля, попълнете всички полета"
            }
        },
        "table_data": {
            "title": "Заглавие",
            "content": "Съдържание",
            "sr_no": "№",
            "name": "Име",
            "email": "Имейл",
            "phone": "Телефон",
            "rating": "Оценка",
            "driver_capital": "ШОФЬОР",
            "customer_capital": "КЛИЕНТ",
            "driver_name": "Име на шофьора",
            "customer_name": "Име на клиента",
            "driver_rating": "Оценка на шофьора",
            "address": "Адрес",
            "tag": "Етикет",
            "start_address": "Начален адрес",
            "end_address": "Краен адрес",
            "ride_id": "Идентификатор на пътуването",
            "sent_by": "Изпратено от",
            "message": "Съобщение",
            "date": "Дата",
            "time": "Време",
            "distance": "Разстояние",
            "status": "Статус",
            "actions": "Действия",
            "view_capital": "ПРЕГЛЕДАЙ",
            "block_capital": "БЛОКИРАЙ",
            "unblock_capital": "РАЗБЛОКИРАЙ",
            "edit": "Редактирай",
            "back": "Назад",
            "delete": "Изтрий",
            "no_record_found": "Няма намерени записи",
            "no_rows_found": "Няма намерени редове",
            "no_data_found": "Няма намерени данни",
            "no_complaints_found": "Няма намерени оплаквания",
            "type": "Тип",
            "base_fee": "Основна такса",
            "price_per_km": "Цена на км",
            "read_more": "Прочетете повече",
            "price_per_min": "Цена на минута",
            "completed": "Завършено",
            "ride_cancel_by_user": "Пътуването е отменено от потребителя",
            "ride_cancel_by_driver": "Пътуването е отменено от шофьора",
            "no_driver_found": "Няма намерен шофьор",
            "ride_expired": "Пътуването е изтекло",
            "date_time": "Дата и час",
            "fair": "Такса",
            "commission": "Комисия",
            "ride_charge": "Такса за пътуване",
            "cancelation_harge": "Такса за анулация",
            "new": "Нов",
            "update": "Актуализирай",
            "cancel": "Отмени",
            "add_page": "Добави страница",
            "questions": "Въпроси",
            "answers": "Отговори",
            "join_on": "Присъединяване на",
            cash_enable: "Активиране на кеш",
            wallet_amount: 'Сума в портфейла'
        },
        "header": {
            "admin_panel": "Административна панел"
        },
        "menu_bar": {
            "dashboard": "Табло",
            "customers": "Клиенти",
            "drivers": "Шофьори",
            "driver_requests": "Заявки за шофьори",
            "rides": "Пътувания",
            "vehicles": "Превозни средства",
            "deleted_customers": "Изтрити клиенти",
            "deleted_drivers": "Изтрити шофьори",
            "content": "Съдържание",
            "complaints": "Оплаквания",
            "notifications": "Уведомления",
            "contact_us": "Свържете се с нас",
            "faqs": "Често задавани въпроси",
            "logout": "Изход"
        },
        "dashboard": {
            "customers": "Клиенти",
            "drivers": "Шофьори",
            "driver_requested": "Заявки за шофьори",
            "rides": "Пътувания",
            "contents": "Съдържание",
            "unresolved_messages": "Неразрешени съобщения",
            "unresolved_complaints": "Неразрешени оплаквания"
        },
        "customer": {
            "customers": "Клиенти",
            "placeholder": "Търсене по имейл и телефонен номер",
            "customer_details": {
                "customer_details": "Данни за клиента",
                "personal_info": "Лична информация",
                "name": "Име",
                "email": "Имейл",
                "phone": "Телефон",
                "reviews_rating": "Отзиви и оценки",
                "address": "Адрес",
                "ride_details": "Детайли на пътуването",
                "reason_of_deletion": "Причина за изтриване"
            }
        },
        "drivers": {
            "drivers": "Шофьори",
            "wallet": {
                "edit_wallet_amount": "Редактиране на сумата в портфейла",
                "reason": "Причина",
                "save": "Запази",
                "placeholder": "Причина",
                "decrease": "Намали",
                "increase": "Увеличи",
                "amount": "Сума",
                "greater_than": "Моля, въведете стойност, по-голяма от",
                "less_than": "Моля, въведете стойност, по-малка от",
                "enter_amount": "Моля, въведете сума",
                "valid_reason": "Моля, въведете валидна причина"
            },
            "placeholder": "Търсене по име, имейл и телефонен номер",
            "driver_info": {
                "driver_infos": "Информация за шофьора",
                "driver_details": "Данни за шофьора",
                "personal_info": "Лична информация",
                "name": "Име",
                "email": "Имейл",
                "phone": "Телефон",
                "bank_account": "Банкова сметка / IBAN",
                "reviews_rating": "Отзиви и оценки",
                "address": "Адрес",
                "rating": "Оценка",
                "status": "Статус",
                "experience": "Опит",
                "car_name": "Марка на колата",
                "car_number": "Регистрационен номер",
                "ride_details": "Детайли на пътуването",
                "no_driver_found": "Няма намерен шофьор",
                "reason_of_deletion": "Причина за изтриване"
            }
        },
        "driver_request": {
            "driver_requests": "Заявки за шофьори",
            "placeholder": "Търсене по име, имейл и телефонен номер",
            "driver_request_info": {
                "driver_request_details": "Данни за заявката на шофьора",
                "accept": "Приеми",
                "reject": "Отхвърли",
                "cancel": "Отмени",
                "driver_info": "Информация за шофьора",
                "name": "Име",
                "email": "Имейл",
                "phone": "Телефон",
                "driver_customer": "Шофьор/Клиент",
                "vehicle_name": "Марка на превозното средство",
                "vehicle_number": "Регистрационен номер на превозното средство",
                "experience": "Опит",
                "rejection": "Отказ",
                "enter_reason_rejection": "Моля, въведете причина за отказ"
            }
        },
        "rides": {
            "rides": "Пътувания",
            "placeholder": "Търсене по адрес",
            "ride_details": {
                "ride_on_map": "Пътуване на картата",
                "ride_details": "Детайли на пътуването",
                download_invoice:"Изтегляне на фактура"
            },
            "rating": {
                "customer": {
                    "customer_rating": "Оценка на клиента",
                    "name": "Име на клиента"
                },
                "driver": {
                    "driver_rating": "Оценка на шофьора",
                    "name": "Име на шофьора",
                    "review": "Отзиви"
                }
            }
        },
        "vehicle": {
            "vehicles": "Превозни средства",
            "error_messages": {
                "commision_required": "Необходима е комисия",
                "only_numeric": "Моля, въведете само числови стойности",
                "price_required": "Цената е задължителна",
                "cancelation_charge_required": "Таксата за анулация е задължителна",
                "base_fee_required": "Основната такса е задължителна"
            },
            "edit_vehicle": {
                "edit_vehicle": "Редактиране на превозното средство",
                "commission": "Комисия",
                "price_per_km": "Цена на км",
                "cancelation_charge": "Такса за анулация",
                "price_per_min": "Цена на минута",
                "ride_charge": "Такса за пътуване",
                "base_fare": "Основна такса",
                "publish": "Публикувай"
            }
        },
        "deleted_customers": {
            "deleted_customers": "Изтрити клиенти",
            "placeholder": "Търсене по клиент, имейл и телефонен номер"
        },
        "content": {
            "content": "Съдържание"
        },
        "complaints": {
            "complaints": "Оплаквания"
        },
        "notifications": {
            "notifications": "Уведомления",
            "send_notifications_to": "Изпрати уведомления на",
            "all_customers": "Всички клиенти",
            "customer": "Клиент",
            "drivers": "Шофьори",
            "selected_customer_drivers": "Избрани клиенти/шофьори",
            "select_notification_type": "Изберете тип уведомление",
            "title": "Заглавие",
            "message": "Съобщение",
            "please_select_user_by_email": "Моля, изберете потребител по имейл",
            "send": "ИЗПРАТИ",
            "push": "Push",
            "email": "Имейл"
        },
        "contact_us": {
            "contact_us": "Свържете се с нас",
            "no_content_found": "Няма намерени съобщения за контакт"
        },
        "faqs": {
            "faqs": "Често задавани въпроси",
            "add": {
                "add": "ДОБАВИ",
                "add_faq": "Добави FAQ",
                "edit_faq": "Редактирай FAQ",
                "save_changes": "Запази промените",
                "cancel": "Отмени",
                "delete_msg": "Сигурни ли сте, че искате да изтриете FAQ",
                "question": "Въпрос",
                "answer": "Отговор",
                "save": "Запази"
            }
        }
    },
    et: {
        "common": {
            "loading": "Laadimine",
            "logout": {
                "logout_message": "Kas olete kindel, et soovite välja logida:",
                "yes": "Jah"
            },
            "dropdown": {
                "all": "Kõik",
                "block": "Blokeeri",
                "unblock": "Tühista blokeering",
                "new": "Uus",
                "rejected": "Tagasi lükatud",
                "pending": "Ootel",
                "resolved": "Lahendatud"
            },
            "documents": {
                "document": "Dokumendid",
                "vehicle_registration": "Sõiduki registreerimine",
                "vehicle_insurance": "Sõiduki kindlustus",
                "driving_licence": "Juhiõigus",
                "pending_documents": "Ootel dokumendid",
                "accept": "Kinnita",
                "reject": "Tagasi lükka"
            },
            "footer": {
                "copyright": "Autoriõigus",
                "all_rights_reserved": "Kõik õigused kaitstud."
            }
        },
        "messages": {
            "error": {
                "error": "Viga",
                "email_not_exist": "E-post ei eksisteeri",
                "enter_title": "Palun sisestage pealkiri",
                "enter_message": "Palun sisestage sõnum",
                "valid_data": "Palun sisestage kehtivad andmed",
                "rejection_reason": "Palun sisestage keeldumise põhjus",
                "provide_reason": "Palun esitage keeldumise põhjus"
            },
            "success": {
                "success": "Edu",
                "user_blocked": "Kasutaja blokeeritud",
                "driver_blocked": "Juht blokeeritud",
                "driver_accepted": "Juht aktsepteeritud",
                "driver_unblocked": "Juht blokeering eemaldatud",
                "user_unblocked": "Kasutaja blokeering eemaldatud",
                "deactivated": "Deaktiveeritud",
                "activated": "Edukas aktiveerimine!!!",
                "complain_resolved": "Kaebus lahendatud.",
                "complain_changed_pending": "Kaebus muudetud ootel.",
                "faq_added": "KKK edukalt lisatud",
                "faq_edited": "KKK edukalt redigeeritud",
                "faq_deleted": "KKK edukalt kustutatud",
                "driver_rejected": "Juht tagasi lükatud",
                "notification_sent": "Teade saadetud",
                "request_accepted": "Päring edukalt vastu võetud",
                "request_rejected": "Päring edukalt tagasi lükatud",
                "document_accepted": "Dokument aktsepteeritud.",
                "document_rejected": "Dokument tagasi lükatud."
            },
            "warn": {
                "fill_all_field": "Palun täitke kõik sisestusväljad"
            }
        },
        "table_data": {
            "title": "Pealkiri",
            "content": "Sisu",
            "sr_no": "Nr.",
            "name": "Nimi",
            "email": "E-post",
            "phone": "Telefon",
            "rating": "Hinne",
            "driver_capital": "JUHT",
            "customer_capital": "KLIENT",
            "driver_name": "Juhi nimi",
            "customer_name": "Kliendi nimi",
            "driver_rating": "Juhi hinne",
            "address": "Aadress",
            "tag": "Silt",
            "start_address": "Alguspunkt",
            "end_address": "Lõpppunkt",
            "ride_id": "Sõidukood",
            "sent_by": "Saadetud",
            "message": "Sõnum",
            "date": "Kuupäev",
            "time": "Aeg",
            "distance": "Kaugus",
            "status": "Olek",
            "actions": "Tegevused",
            "view_capital": "Kuva",
            "block_capital": "BLOKEERI",
            "unblock_capital": "Tühista blokeering",
            "edit": "Redigeeri",
            "back": "Tagasi",
            "delete": "Kustuta",
            "no_record_found": "Kirjeid ei leitud",
            "no_rows_found": "Ridu ei leitud",
            "no_data_found": "Andmeid ei leitud",
            "no_complaints_found": "Kaebusi ei leitud",
            "type": "Tüüp",
            "base_fee": "Põhitasu",
            "price_per_km": "Hind km kohta",
            "read_more": "Loe rohkem",
            "price_per_min": "Hind minutis",
            "completed": "Lõpetatud",
            "ride_cancel_by_user": "Sõit tühistatud kasutaja poolt",
            "ride_cancel_by_driver": "Sõit tühistatud juhi poolt",
            "no_driver_found": "Juhti ei leitud",
            "ride_expired": "Sõidu aeg on lõppenud",
            "date_time": "Kuupäev ja kellaaeg",
            "fair": "Hind",
            "commission": "Komisjonitasu",
            "ride_charge": "Sõidutasu",
            "cancelation_charge": "Tühistamistasu",
            "new": "Uus",
            "update": "Uuenda",
            "cancel": "Tühista",
            "add_page": "Lisa leht",
            "questions": "Küsimused",
            "answers": "Vastused",
            "join_on": "Liitu kuupäeval",
            cash_enable: 'Sularaha lubamine',
            wallet_amount: 'Rahakoti summa'
        },
        "header": {
            "admin_panel": "Administreerimislaud"
        },
        "menu_bar": {
            "dashboard": "Koduleht",
            "customers": "Kliendid",
            "drivers": "Juhid",
            "driver_requests": "Juhi taotlused",
            "rides": "Sõidud",
            "vehicles": "Sõidukid",
            "deleted_customers": "Kustutatud kliendid",
            "deleted_drivers": "Kustutatud juhid",
            "content": "Sisu",
            "complaints": "Kaebused",
            "notifications": "Teavitused",
            "contact_us": "Võta meiega ühendust",
            "faqs": "KKK",
            "logout": "Logi välja"
        },
        "dashboard": {
            "customers": "Kliendid",
            "drivers": "Juhid",
            "driver_requested": "Juht taotletud",
            "rides": "Sõidud",
            "contents": "Sisu",
            "unresolved_messages": "Lahendamata sõnumid",
            "unresolved_complaints": "Lahendamata kaebused"
        },
        "customer": {
            "customers": "Kliendid",
            "placeholder": "Otsi kliendi e-posti ja telefoninumbri järgi",
            "customer_details": {
                "customer_details": "Kliendi andmed",
                "personal_info": "Isiklikud andmed",
                "name": "Nimi",
                "email": "E-post",
                "phone": "Telefon",
                "reviews_rating": "Arvustused ja hinne",
                "address": "Aadress",
                "ride_details": "Sõidu andmed",
                "reason_of_deletion": "Kustutamise põhjus"
            }
        },
        "drivers": {
            "drivers": "Juhid",
            "wallet": {
                "edit_wallet_amount": "Muuda rahakoti summat",
                "reason": "Põhjus",
                "save": "Salvesta",
                "placeholder": "Põhjus",
                "decrease": "Vähenda",
                "increase": "Suurenda",
                "amount": "Summa",
                "greater_than": "Palun sisestage väärtus, mis on suurem kui",
                "less_than": "Palun sisestage väärtus, mis on väiksem kui",
                "enter_amount": "Palun sisestage summa",
                "valid_reason": "Palun sisestage kehtiv põhjus"
            },
            "placeholder": "Otsi nime, e-posti ja telefoninumbri järgi",
            "driver_info": {
                "driver_infos": "Juhi andmed",
                "driver_details": "Juhi andmed",
                "personal_info": "Isiklikud andmed",
                "name": "Nimi",
                "email": "E-post",
                "phone": "Telefon",
                "bank_account": "Panga konto number / IBAN",
                "reviews_rating": "Arvustused ja hinne",
                "address": "Aadress",
                "rating": "Hinne",
                "status": "Olek",
                "experience": "Kogemus",
                "car_name": "Sõiduki nimi",
                "car_number": "Sõiduki number",
                "ride_details": "Sõidu andmed",
                "no_driver_found": "Juhti ei leitud",
                "reason_of_deletion": "Kustutamise põhjus"
            }
        },
        "driver_request": {
            "driver_requests": "Juhi taotlused",
            "placeholder": "Otsi nime, e-posti ja telefoninumbri järgi",
            "driver_request_info": {
                "driver_request_details": "Juhi taotluse andmed",
                "accept": "Kinnita",
                "reject": "Tagasi lükka",
                "cancel": "Tühista",
                "driver_info": "Juhi andmed",
                "name": "Nimi",
                "email": "E-post",
                "phone": "Telefon",
                "driver_customer": "Juht/Klient",
                "vehicle_name": "Sõiduki nimi",
                "vehicle_number": "Sõiduki number",
                "experience": "Kogemus",
                "rejection": "Tagasilükkamine",
                "enter_reason_rejection": "Palun sisestage tagasilükkamise põhjus"
            }
        },
        "rides": {
            "rides": "Sõidud",
            "placeholder": "Otsi aadressi järgi",
            "ride_details": {
                "ride_on_map": "Sõit kaardil",
                "ride_details": "Sõidu andmed",
                download_invoice:"Laadi alla arve"
            },
            "rating": {
                "customer": {
                    "customer_rating": "Kliendi hinne",
                    "name": "Kliendi nimi"
                },
                "driver": {
                    "driver_rating": "Juhi hinne",
                    "name": "Juhi nimi",
                    "review": "Arvustused"
                }
            }
        },
        "vehicle": {
            "vehicles": "Sõidukid",
            "error_messages": {
                "commision_required": "Komisjonitasu on kohustuslik",
                "only_numeric": "Palun sisestage ainult numbrilised väärtused",
                "price_required": "Hind on kohustuslik",
                "cancelation_charge_required": "Tühistamistasu on kohustuslik",
                "base_fee_required": "Põhitasu on kohustuslik"
            },
            "edit_vehicle": {
                "edit_vehicle": "Redigeeri sõidukit",
                "commission": "Komisjonitasu",
                "price_per_km": "Hind km kohta",
                "cancelation_charge": "Tühistamistasud",
                "price_per_min": "Hind minutis",
                "ride_charge": "Sõidutasu",
                "base_fare": "Põhihind",
                "publish": "Publitseeri"
            }
        },
        "deleted_customers": {
            "deleted_customers": "Kustutatud kliendid",
            "placeholder": "Otsi kliendi, e-posti ja telefoninumbri järgi"
        },
        "content": {
            "content": "Sisu"
        },
        "complaints": {
            "complaints": "Kaebused"
        },
        "notifications": {
            "notifications": "Teavitused",
            "send_notifications_to": "Saada teavitused",
            "all_customers": "Kõigile klientidele",
            "customer": "Kliendile",
            "drivers": "Juhtidele",
            "selected_customer_drivers": "Valitud kliendile/juhtidele",
            "select_notification_type": "Vali teavituse tüüp",
            "title": "Pealkiri",
            "message": "Sõnum",
            "please_select_user_by_email": "Palun vali kasutaja e-posti järgi",
            "send": "SAADA",
            "push": "Push",
            "email": "E-post"
        },
        "contact_us": {
            "contact_us": "Võta meiega ühendust",
            "no_content_found": "Kontaktivestlusi ei leitud"
        },
        "faqs": {
            "faqs": "KKK",
            "add": {
                "add": "LISA",
                "add_faq": "Lisa KKK",
                "edit_faq": "Redigeeri KKK-d",
                "save_changes": "Salvesta muudatused",
                "cancel": "Tühista",
                "delete_msg": "Kas olete kindel, et soovite KKK kustutada?",
                "question": "Küsimus",
                "answer": "Vastus",
                "save": "Salvesta"
            }
        }
    },
    lv: {
        "common": {
            "loading": "Ielādēšana",
            "logout": {
                "logout_message": "Vai tiešām vēlaties izrakstīties:",
                "yes": "Jā"
            },
            "dropdown": {
                "all": "Visi",
                "block": "Blokēt",
                "unblock": "Atbloķēt",
                "new": "Jauns",
                "rejected": "Noraidīts",
                "pending": "Gaida",
                "resolved": "Atrisināts"
            },
            "documents": {
                "document": "Dokumenti",
                "vehicle_registration": "Transportlīdzekļa reģistrācija",
                "vehicle_insurance": "Transportlīdzekļa apdrošināšana",
                "driving_licence": "Transportlīdzekļa vadītāja apliecība",
                "pending_documents": "Gaidošie dokumenti",
                "accept": "Pieņemt",
                "reject": "Noraidīt"
            },
            "footer": {
                "copyright": "Autortiesības",
                "all_rights_reserved": "Visas tiesības aizsargātas."
            }
        },
        "messages": {
            "error": {
                "error": "Kļūda",
                "email_not_exist": "E-pasts nepastāv",
                "enter_title": "Lūdzu, ievadiet nosaukumu",
                "enter_message": "Lūdzu, ievadiet ziņojumu",
                "valid_data": "Lūdzu, ievadiet derīgus datus",
                "rejection_reason": "Lūdzu, ievadiet noraidīšanas iemeslu",
                "provide_reason": "Lūdzu, norādiet noraidīšanas iemeslu"
            },
            "success": {
                "success": "Veiksmīgi",
                "user_blocked": "Lietotājs bloķēts",
                "driver_blocked": "Vadītājs bloķēts",
                "driver_accepted": "Vadītājs pieņemts",
                "driver_unblocked": "Vadītājs atbloķēts",
                "user_unblocked": "Lietotājs atbloķēts",
                "deactivated": "Deaktivizēts",
                "activated": "Veiksmīgi aktivizēts!!!",
                "complain_resolved": "Sūdzība atrisināta.",
                "complain_changed_pending": "Sūdzība mainīta kā nepabeigta.",
                "faq_added": "BUJ veiksmīgi pievienots",
                "faq_edited": "BUJ veiksmīgi rediģēts",
                "faq_deleted": "BUJ veiksmīgi izdzēsts",
                "driver_rejected": "Vadītājs noraidīts",
                "notification_sent": "Paziņojums nosūtīts",
                "request_accepted": "Pieprasījums veiksmīgi pieņemts",
                "request_rejected": "Pieprasījums veiksmīgi noraidīts",
                "document_accepted": "Dokuments pieņemts.",
                "document_rejected": "Dokuments noraidīts."
            },
            "warn": {
                "fill_all_field": "Lūdzu, aizpildiet visus ievades laukus"
            }
        },
        "table_data": {
            "title": "Virsraksts",
            "content": "Saturs",
            "sr_no": "Nr.",
            "name": "Vārds",
            "email": "E-pasts",
            "phone": "Tālrunis",
            "rating": "Vērtējums",
            "driver_capital": "VADĪTĀJS",
            "customer_capital": "KLIENTS",
            "driver_name": "Vadītāja vārds",
            "customer_name": "Klienta vārds",
            "driver_rating": "Vadītāja vērtējums",
            "address": "Adrese",
            "tag": "Etiķete",
            "start_address": "Sākuma adrese",
            "end_address": "Beigu adrese",
            "ride_id": "Brauciena ID",
            "sent_by": "Nosūtījis",
            "message": "Ziņojums",
            "date": "Datums",
            "time": "Laiks",
            "distance": "Attālums",
            "status": "Statuss",
            "actions": "Darbības",
            "view_capital": "APSKEIT",
            "block_capital": "BLOKĒT",
            "unblock_capital": "ATBLOKĒT",
            "edit": "Rediģēt",
            "back": "Atpakaļ",
            "delete": "Dzēst",
            "no_record_found": "Nav atrasts neviens ieraksts",
            "no_rows_found": "Nav atrastas rindas",
            "no_data_found": "Dati netika atrasti",
            "no_complaints_found": "Nav atrastas sūdzības",
            "type": "Tips",
            "base_fee": "Pamata maksa",
            "price_per_km": "Cena par KM",
            "read_more": "Lasīt vairāk",
            "price_per_min": "Cena par minūti",
            "completed": "Pabeigts",
            "ride_cancel_by_user": "Brauciens atcelts no lietotāja puses",
            "ride_cancel_by_driver": "Brauciens atcelts no vadītāja puses",
            "no_driver_found": "Nav atrasts neviens vadītājs",
            "ride_expired": "Brauciens ir beidzies",
            "date_time": "Datums un laiks",
            "fair": "Fairs",
            "commission": "Komisija",
            "ride_charge": "Brauciena maksa",
            "cancelation_harge": "Atcelšanas maksa",
            "new": "Jauns",
            "update": "Atjaunināt",
            "cancel": "Atcelt",
            "add_page": "Pievienot lapu",
            "questions": "Jautājumi",
            "answers": "Atbildes",
            "join_on": "Pievienoties",
            cash_enable: 'Atļaut skaidru naudu',
            wallet_amount: 'Maka summa'
        },
        "header": {
            "admin_panel": "Admina panelis"
        },
        "menu_bar": {
            "dashboard": "Vadības panelis",
            "customers": "Klienti",
            "drivers": "Vadītāji",
            "driver_requests": "Vadītāju pieprasījumi",
            "rides": "Braucieni",
            "vehicles": "Transportlīdzekļi",
            "deleted_customers": "Dzēsti klienti",
            "deleted_drivers": "Dzēsti vadītāji",
            "content": "Saturs",
            "complaints": "Sūdzības",
            "notifications": "Paziņojumi",
            "contact_us": "Sazinieties ar mums",
            "faqs": "BUJ",
            "logout": "Izrakstīties"
        },
        "dashboard": {
            "customers": "Klienti",
            "drivers": "Vadītāji",
            "driver_requested": "Vadītāju pieprasījumi",
            "rides": "Braucieni",
            "contents": "Saturs",
            "unresolved_messages": "Nerisinātie ziņojumi",
            "unresolved_complaints": "Nerisinātās sūdzības"
        },
        "customer": {
            "customers": "Klienti",
            "placeholder": "Meklēt klientu pēc e-pasta un tālruņa numura",
            "customer_details": {
                "customer_details": "Klienta detaļas",
                "personal_info": "Personīgā informācija",
                "name": "Vārds",
                "email": "E-pasts",
                "phone": "Tālrunis",
                "reviews_rating": "Atsauksmes un vērtējumi",
                "address": "Adrese",
                "ride_details": "Brauciena detaļas",
                "reason_of_deletion": "Dzēšanas iemesls"
            }
        },
        "drivers": {
            "drivers": "Vadītāji",
            "wallet": {
                "edit_wallet_amount": "Rediģēt makā esošo summu",
                "reason": "Iemesls",
                "save": "Saglabāt",
                "placeholder": "Iemesls",
                "decrease": "Samazināt",
                "increase": "Palielināt",
                "amount": "Summa",
                "greater_than": "Lūdzu, ievadiet vērtību, kas ir lielāka par",
                "less_than": "Lūdzu, ievadiet vērtību, kas ir mazāka par",
                "enter_amount": "Lūdzu, ievadiet summu",
                "valid_reason": "Lūdzu, ievadiet derīgu iemeslu"
            }
            ,
            "placeholder": "Meklēt pēc vārda, e-pasta un tālruņa numura",
            "driver_info": {
                "driver_infos": "Vadītāja informācija",
                "driver_details": "Vadītāja detaļas",
                "personal_info": "Personīgā informācija",
                "name": "Vārds",
                "email": "E-pasts",
                "phone": "Tālrunis",
                "bank_account": "Bankas konta numurs / IBAN",
                "reviews_rating": "Atsauksmes un vērtējumi",
                "address": "Adrese",
                "rating": "Vērtējums",
                "status": "Statuss",
                "experience": "Pieredze",
                "car_name": "Auto nosaukums",
                "car_number": "Auto numurs",
                "ride_details": "Brauciena detaļas",
                "no_driver_found": "Nav atrasts neviens vadītājs",
                "reason_of_deletion": "Dzēšanas iemesls"
            }
        },
        "driver_request": {
            "driver_requests": "Vadītāju pieprasījumi",
            "placeholder": "Meklēt pēc vārda, e-pasta un tālruņa numura",
            "driver_request_info": {
                "driver_request_details": "Vadītāja pieprasījuma detaļas",
                "accept": "Pieņemt",
                "reject": "Noraidīt",
                "cancel": "Atcelt",
                "driver_info": "Vadītāja informācija",
                "name": "Vārds",
                "email": "E-pasts",
                "phone": "Tālrunis",
                "driver_customer": "Vadītājs/Klients",
                "vehicle_name": "Transportlīdzekļa nosaukums",
                "vehicle_number": "Transportlīdzekļa numurs",
                "experience": "Pieredze",
                "rejection": "Noraidījums",
                "enter_reason_rejection": "Lūdzu, ievadiet noraidījuma iemeslu"
            }
        },
        "rides": {
            "rides": "Braucieni",
            "placeholder": "Meklēt pēc adreses",
            "ride_details": {
                "ride_on_map": "Brauciens kartē",
                "ride_details": "Brauciena detaļas",
                download_invoice:"Lejupielādēt rēķinu"
            },
            "rating": {
                "customer": {
                    "customer_rating": "Klienta vērtējums",
                    "name": "Klienta vārds"
                },
                "driver": {
                    "driver_rating": "Vadītāja vērtējums",
                    "name": "Vadītāja vārds",
                    "review": "Atsauksmes"
                }
            }
        },
        "vehicle": {
            "vehicles": "Transportlīdzekļi",
            "error_messages": {
                "commision_required": "Komisija ir obligāta",
                "only_numeric": "Lūdzu, ievadiet tikai ciparus",
                "price_required": "Cena ir obligāta",
                "cancelation_charge_required": "Atcelšanas maksa ir obligāta",
                "base_fee_required": "Pamata maksa ir obligāta"
            },
            "edit_vehicle": {
                "edit_vehicle": "Rediģēt transportlīdzekli",
                "commission": "Komisija",
                "price_per_km": "Cena par KM",
                "cancelation_charge": "Atcelšanas maksa",
                "price_per_min": "Cena par minūti",
                "ride_charge": "Brauciena maksa",
                "base_fare": "Pamata maksa",
                "publish": "Publicēt"
            }
        },
        "deleted_customers": {
            "deleted_customers": "Dzēsti klienti",
            "placeholder": "Meklēt klientu, e-pastu un tālruņa numuru"
        },
        "content": {
            "content": "Saturs"
        },
        "complaints": {
            "complaints": "Sūdzības"
        },
        "notifications": {
            "notifications": "Paziņojumi",
            "send_notifications_to": "Sūtīt paziņojumus uz",
            "all_customers": "Visi klienti",
            "customer": "Klients",
            "drivers": "Vadītāji",
            "selected_customer_drivers": "Atlasītie klienti/vadītāji",
            "select_notification_type": "Izvēlieties paziņojuma veidu",
            "title": "Virsraksts",
            "message": "Ziņojums",
            "please_select_user_by_email": "Lūdzu, izvēlieties lietotāju pēc e-pasta",
            "send": "SŪTĪT",
            "push": "Push",
            "email": "E-pasts"
        },
        "contact_us": {
            "contact_us": "Sazinieties ar mums",
            "no_content_found": "Nav atrasts neviens ziņojums"
        },
        "faqs": {
            "faqs": "BUJ",
            "add": {
                "add": "PIEVIENOT",
                "add_faq": "Pievienot BUJ",
                "edit_faq": "Rediģēt BUJ",
                "save_changes": "Saglabāt izmaiņas",
                "cancel": "Atcelt",
                "delete_msg": "Vai esat pārliecināts, ka vēlaties dzēst BUJ?",
                "question": "Jautājums",
                "answer": "Atbilde",
                "save": "Saglabāt"
            }
        }
    },
    lt: {
        common: {
            loading: "Įkeliamas",
            "logout": {
                "logout_message": "Ar tikrai norite atsijungti:",
                "yes": "Taip"
            },
            dropdown: {
                all: "Visi",
                block: "Blokuoti",
                unblock: "Atblokuoti",
                new: "Naujas",
                rejected: "Atmestas",
                pending: "Laukiama",
                resolved: "Išspręstas",
            },
            documents: {
                document: "Dokumentai",
                vehicle_registration: "Transporto priemonės registracija",
                vehicle_insurance: "Transporto priemonės draudimas",
                driving_licence: "Vairuotojo pažymėjimas",
                pending_documents: "Laukiami dokumentai",
                accept: "Priimti",
                reject: "Atmesti"
            },
            "footer": {
                "copyright": "Autorių teisės",
                "all_rights_reserved": "Visos teisės saugomos."
            }
        },
        "messages": {
            "error": {
                "error": "Klaida",
                "email_not_exist": "El. paštas neegzistuoja",
                "enter_title": "Prašome įvesti pavadinimą",
                "enter_message": "Prašome įvesti žinutę",
                "valid_data": "Prašome įvesti galiojančius duomenis",
                "rejection_reason": "Prašome įvesti atmetimo priežastį",
                "provide_reason": "Prašome pateikti atmetimo priežastį"
            },
            "success": {
                "success": "Pavyko",
                "user_blocked": "Vartotojas užblokuotas",
                "driver_blocked": "Vairuotojas užblokuotas",
                "driver_accepted": "Vairuotojas priimtas",
                "driver_unblocked": "Vairuotojas atblokuotas",
                "user_unblocked": "Vartotojas atblokuotas",
                "deactivated": "Deaktyvuota",
                "activated": "Sėkmingai aktyvuota!!!",
                "complain_resolved": "Skundas išspręstas.",
                "complain_changed_pending": "Skundas pakeistas kaip laukiantis.",
                "faq_added": "DUK sėkmingai pridėtas",
                "faq_edited": "DUK sėkmingai redaguotas",
                "faq_deleted": "DUK sėkmingai ištrintas",
                "driver_rejected": "Vairuotojas atmestas",
                "notification_sent": "Pranešimas išsiųstas",
                "request_accepted": "Prašymas sėkmingai priimtas",
                "request_rejected": "Prašymas sėkmingai atmestas",
                "document_accepted": "Dokumentas priimtas.",
                "document_rejected": "Dokumentas atmestas."
            },
            "warn": {
                "fill_all_field": "Prašome užpildyti visus įvesties laukus"
            }
        },
        table_data: {
            title: "Pavadinimas",
            content: "Turinys",
            sr_no: "Nr.",
            name: "Vardas",
            email: "El. paštas",
            phone: "Telefonas",
            rating: "Įvertinimas",
            driver_capital: "Vairuotojas",
            customer_capital: "Klientas",
            driver_name: "Vairuotojo vardas",
            customer_name: "Kliento vardas",
            driver_rating: "Vairuotojo įvertinimas",
            address: "Adresas",
            tag: "Žyma",
            start_address: "Pradžios adresas",
            end_address: "Pabaigos adresas",
            ride_id: "Važiavimo ID",
            sent_by: "Išsiuntė",
            message: "Žinutė",
            date: "Data",
            time: "Laikas",
            distance: "Atstumas",
            status: "Būsena",
            actions: "Veiksmai",
            view_capital: "PERŽIŪRĖTI",
            block_capital: "BLOKUOTI",
            unblock_capital: "ATBLOKUOTI",
            edit: "Redaguoti",
            back: "Atgal",
            delete: "Ištrinti",
            no_record_found: "Įrašas nerastas",
            no_rows_found: "Eilučių nerasta",
            no_data_found: "Duomenų nerasta",
            no_complaints_found: "Skundų nerasta",
            type: "Tipas",
            base_fee: "Pagrindinis mokestis",
            price_per_km: "Kaina už km",
            read_more: "Skaityti daugiau",
            price_per_min: "Kaina už min",
            completed: "Užbaigtas",
            ride_cancel_by_user: "Važiavimas atšauktas vartotojo",
            ride_cancel_by_driver: "Važiavimas atšauktas vairuotojo",
            no_driver_found: "Vairuotojas nerastas",
            ride_expired: "Važiavimas pasibaigęs",
            date_time: "Data ir laikas",
            fair: "Tarifas",
            commission: "Komisiniai",
            ride_charge: "Važiavimo kaina",
            cancelation_charge: "Atšaukimo mokestis",
            new: "Naujas",
            update: "Atnaujinti",
            cancel: "Atšaukti",
            add_page: "Pridėti puslapį",
            questions: "Klausimai",
            answers: "Atsakymai",
            join_on: "Prisijungti",
            cash_enable: 'Įjungti grynuosius pinigus',
            wallet_amount: 'Piniginės suma'
        },
        header: {
            admin_panel: "Administratoriaus panelė"
        },
        menu_bar: {
            dashboard: "Pagrindinis puslapis",
            customers: "Klientai",
            drivers: "Vairuotojai",
            driver_requests: "Vairuotojų užklausos",
            rides: "Važiavimai",
            vehicles: "Transporto priemonės",
            deleted_customers: "Ištrinti klientai",
            deleted_drivers: "Ištrinti vairuotojai",
            content: "Turinys",
            complaints: "Skundai",
            notifications: "Pranešimai",
            contact_us: "Susisiekite su mumis",
            faqs: "D.U.K.",
            logout: "Atsijungti"
        },
        dashboard: {
            customers: "Klientai",
            drivers: "Vairuotojai",
            driver_requested: "Prašoma vairuotojo",
            rides: "Važiavimai",
            contents: "Turinys",
            unresolved_messages: "Neišspręsti pranešimai",
            unresolved_complaints: "Neišspręsti skundai"
        },
        customer: {
            customers: "Klientai",
            placeholder: "Ieškoti kliento pagal el. paštą ir telefono numerį",
            customer_details: {
                customer_details: "Kliento informacija",
                personal_info: "Asmeninė informacija",
                name: "Vardas",
                email: "El. paštas",
                phone: "Telefonas",
                reviews_rating: "Atsiliepimai ir įvertinimas",
                address: "Adresas",
                ride_details: "Važiavimo informacija",
                reason_of_deletion: "Pašalinimo priežastis"
            }
        },
        drivers: {
            drivers: "Vairuotojai",
            "wallet": {
                "edit_wallet_amount": "Redaguoti piniginės sumą",
                "reason": "Priežastis",
                "save": "Išsaugoti",
                "placeholder": "Priežastis",
                "decrease": "Sumažinti",
                "increase": "Padidinti",
                "amount": "Suma",
                "greater_than": "Prašome įvesti vertę, kuri yra didesnė nei",
                "less_than": "Prašome įvesti vertę, kuri yra mažesnė nei",
                "enter_amount": "Prašome įvesti sumą",
                "valid_reason": "Prašome įvesti galiojančią priežastį"
            },
            placeholder: "Ieškoti pagal vardą, el. paštą ir telefono numerį",
            driver_info: {
                driver_infos: "Vairuotojo informacija",
                driver_details: "Vairuotojo detalės",
                personal_info: "Asmeninė informacija",
                name: "Vardas",
                email: "El. paštas",
                phone: "Telefonas",
                bank_account: "Banko sąskaitos numeris / IBAN",
                reviews_rating: "Atsiliepimai ir įvertinimas",
                address: "Adresas",
                rating: "Įvertinimas",
                status: "Būsena",
                experience: "Patirtis",
                car_name: "Automobilio pavadinimas",
                car_number: "Automobilio numeris",
                ride_details: "Važiavimo informacija",
                no_driver_found: "Vairuotojas nerastas",
                reason_of_deletion: "Pašalinimo priežastis"
            }
        },
        driver_request: {
            driver_requests: "Vairuotojų užklausos",
            placeholder: "Ieškoti pagal vardą, el. paštą ir telefono numerį",
            driver_request_info: {
                driver_request_details: "Vairuotojo užklausos detalės",
                accept: "Priimti",
                reject: "Atmesti",
                cancel: "Atšaukti",
                driver_info: "Vairuotojo informacija",
                name: "Vardas",
                email: "El. paštas",
                phone: "Telefonas",
                driver_customer: "Vairuotojas/klientas",
                vehicle_name: "Transporto priemonės pavadinimas",
                vehicle_number: "Transporto priemonės numeris",
                experience: "Patirtis",
                rejection: "Atmetimo priežastis",
                enter_reason_rejection: "Įveskite atmetimo priežastį"
            }
        },
        rides: {
            rides: "Važiavimai",
            placeholder: "Ieškoti pagal adresą",
            ride_details: {
                ride_on_map: "Važiavimas žemėlapyje",
                ride_details: "Važiavimo informacija",
                download_invoice:"Atsisiųsti sąskaitą"
            },
            rating: {
                customer: {
                    customer_rating: "Kliento įvertinimas",
                    name: "Kliento vardas"
                },
                driver: {
                    driver_rating: "Vairuotojo įvertinimas",
                    name: "Vairuotojo vardas",
                    review: "Atsiliepimai"
                }
            }
        },
        vehicle: {
            vehicles: "Transporto priemonės",
            error_messages: {
                commision_required: "Komisiniai yra būtini",
                only_numeric: "Įveskite tik skaitines vertes",
                price_required: "Kaina yra būtina",
                cancelation_charge_required: "Atšaukimo mokestis yra būtinas",
                base_fee_required: "Pagrindinis mokestis yra būtinas"
            },
            edit_vehicle: {
                edit_vehicle: "Redaguoti transporto priemonę",
                commission: "Komisiniai",
                price_per_km: "Kaina už km",
                cancelation_charge: "Atšaukimo mokestis",
                price_per_min: "Kaina už min",
                ride_charge: "Važiavimo kaina",
                base_fare: "Pagrindinė kaina",
                publish: "Skelbti"
            }
        },
        deleted_customers: {
            deleted_customers: "Ištrinti klientai",
            placeholder: "Ieškoti kliento, el. pašto ir telefono numerio"
        },
        content: {
            content: "Turinys"
        },
        complaints: {
            complaints: "Skundai"
        },
        notifications: {
            notifications: "Pranešimai",
            send_notifications_to: "Siųsti pranešimus",
            all_customers: "Visiems klientams",
            customer: "Klientui",
            drivers: "Vairuotojams",
            selected_customer_drivers: "Pasirinkti klientai/vairuotojai",
            select_notification_type: "Pasirinkite pranešimo tipą",
            title: "Antraštė",
            message: "Žinutė",
            please_select_user_by_email: "Prašome pasirinkti vartotoją pagal el. paštą",
            send: "SIŲSTI",
            push: "Push",
            email: "El. paštas"
        },
        contact_us: {
            contact_us: "Susisiekite su mumis",
            no_content_found: "Žinutės nerasta"
        },
        faqs: {
            faqs: "D.U.K.",
            add: {
                add: "PRIDĖTI",
                add_faq: "Pridėti D.U.K.",
                edit_faq: "Redaguoti D.U.K.",
                save_changes: "Išsaugoti pakeitimus",
                cancel: "Atšaukti",
                delete_msg: "Ar tikrai norite ištrinti D.U.K.?",
                question: "Klausimas",
                answer: "Atsakymas",
                save: "Išsaugoti"
            }
        }
    },
    ro: {
        "common": {
            "loading": "Încărcare",
            "logout": {
                "logout_message": "Sigur doriți să vă deconectați:",
                "yes": "Da"
            },
            "dropdown": {
                "all": "Toate",
                "block": "Blochează",
                "unblock": "Deblochează",
                "new": "Nou",
                "rejected": "Respins",
                "pending": "În așteptare",
                "resolved": "Rezolvat"
            },
            "documents": {
                "document": "Documente",
                "vehicle_registration": "Înregistrare Vehicul",
                "vehicle_insurance": "Asigurare Vehicul",
                "driving_licence": "Permis de Conducere",
                "pending_documents": "Documente în așteptare",
                "accept": "Acceptă",
                "reject": "Respinge"
            },
            "footer": {
                "copyright": "Drepturi de autor",
                "all_rights_reserved": "Toate drepturile rezervate."
            }
        },
        "messages": {
            "error": {
                "error": "Eroare",
                "email_not_exist": "Email-ul nu există",
                "enter_title": "Vă rugăm să introduceți titlul",
                "enter_message": "Vă rugăm să introduceți mesajul",
                "valid_data": "Vă rugăm să introduceți date valide",
                "rejection_reason": "Vă rugăm să introduceți motivul respingerii",
                "provide_reason": "Vă rugăm să furnizați motivul respingerii"
            },
            "success": {
                "success": "Succes",
                "user_blocked": "Utilizator blocat",
                "driver_blocked": "Șofer blocat",
                "driver_accepted": "Șofer acceptat",
                "driver_unblocked": "Șofer deblocat",
                "user_unblocked": "Utilizator deblocat",
                "deactivated": "Dezactivat",
                "activated": "Activat cu succes!!!",
                "complain_resolved": "Reclamație rezolvată.",
                "complain_changed_pending": "Reclamație schimbată ca în așteptare.",
                "faq_added": "Întrebări frecvente adăugate cu succes",
                "faq_edited": "Întrebări frecvente editate cu succes",
                "faq_deleted": "Întrebări frecvente șterse cu succes",
                "driver_rejected": "Șofer respins",
                "notification_sent": "Notificare trimisă",
                "request_accepted": "Cerere acceptată cu succes",
                "request_rejected": "Cerere respinsă cu succes",
                "document_accepted": "Document acceptat.",
                "document_rejected": "Document respins."
            },
            "warn": {
                "fill_all_field": "Vă rugăm să completați toate câmpurile"
            }
        },
        "table_data": {
            "title": "Titlu",
            "content": "Conținut",
            "sr_no": "Nr. Crt.",
            "name": "Nume",
            "email": "Email",
            "phone": "Telefon",
            "rating": "Rating",
            "driver_capital": "ȘOFER",
            "customer_capital": "CLIENT",
            "driver_name": "Nume Șofer",
            "customer_name": "Nume Client",
            "driver_rating": "Rating Șofer",
            "address": "Adresă",
            "tag": "Etichetă",
            "start_address": "Adresă de Start",
            "end_address": "Adresă de Sfârșit",
            "ride_id": "ID Călătorie",
            "sent_by": "Trimis de",
            "message": "Mesaj",
            "date": "Data",
            "time": "Ora",
            "distance": "Distanță",
            "status": "Status",
            "actions": "Acțiuni",
            "view_capital": "VIZUALIZEAZĂ",
            "block_capital": "BLOCHEAZĂ",
            "unblock_capital": "DEBLOCHEAZĂ",
            "edit": "Editează",
            "back": "Înapoi",
            "delete": "Șterge",
            "no_record_found": "Niciun înregistrare găsită",
            "no_rows_found": "Nicio linie găsită",
            "no_data_found": "Niciun date găsite",
            "no_complaints_found": "Niciun reclamație găsită",
            "type": "Tip",
            "base_fee": "Taxă de Bază",
            "price_per_km": "Preț pe KM",
            "read_more": "Citește mai mult",
            "price_per_min": "Preț pe Minut",
            "completed": "Finalizat",
            "ride_cancel_by_user": "Călătoria anulată de utilizator",
            "ride_cancel_by_driver": "Călătoria anulată de șofer",
            "no_driver_found": "Niciun șofer găsit",
            "ride_expired": "Călătoria a expirat",
            "date_time": "Data & Ora",
            "fair": "Tarif",
            "commission": "Comision",
            "ride_charge": "Cost Călătorie",
            "cancelation_charge": "Taxă Anulare",
            "new": "Nou",
            "update": "Actualizează",
            "cancel": "Anulează",
            "add_page": "Adaugă Pagină",
            "questions": "Întrebări",
            "answers": "Răspunsuri",
            "join_on": "Alăturați-vă pe",
            cash_enable: 'Activare numerar',
            wallet_amount: 'Suma din portofel'
        },
        "header": {
            "admin_panel": "Panou Admin"
        },
        "menu_bar": {
            "dashboard": "Tablou de Bord",
            "customers": "Clienți",
            "drivers": "Șoferi",
            "driver_requests": "Cereri Șoferi",
            "rides": "Călătorii",
            "vehicles": "Vehicule",
            "deleted_customers": "Clienți Șterși",
            "deleted_drivers": "Șoferi Șterși",
            "content": "Conținut",
            "complaints": "Reclamații",
            "notifications": "Notificări",
            "contact_us": "Contactează-ne",
            "faqs": "Întrebări Frecvente",
            "logout": "Deconectare"
        },
        "dashboard": {
            "customers": "Clienți",
            "drivers": "Șoferi",
            "driver_requested": "Cerere Șofer",
            "rides": "Călătorii",
            "contents": "Conținut",
            "unresolved_messages": "Mesaje Nerezolvate",
            "unresolved_complaints": "Reclamații Nerezolvate"
        },
        "customer": {
            "customers": "Clienți",
            "placeholder": "Caută client după email și număr de telefon",
            "customer_details": {
                "customer_details": "Detalii Client",
                "personal_info": "Informații Personale",
                "name": "Nume",
                "email": "Email",
                "phone": "Telefon",
                "reviews_rating": "Recenzii & Rating",
                "address": "Adresă",
                "ride_details": "Detalii Călătorie",
                "reason_of_deletion": "Motivul ștergerii"
            }
        },
        "drivers": {
            "drivers": "Șoferi",
            "wallet": {
                "edit_wallet_amount": "Editați suma portofelului",
                "reason": "Motiv",
                "save": "Salvați",
                "placeholder": "Motiv",
                "decrease": "Scădere",
                "increase": "Creștere",
                "amount": "Sumă",
                "greater_than": "Vă rugăm să introduceți o valoare mai mare decât",
                "less_than": "Vă rugăm să introduceți o valoare mai mică decât",
                "enter_amount": "Vă rugăm să introduceți o sumă",
                "valid_reason": "Vă rugăm să introduceți un motiv valid"
            },
            "placeholder": "Caută nume, email și număr de telefon",
            "driver_info": {
                "driver_infos": "Informații Șofer",
                "driver_details": "Detalii Șofer",
                "personal_info": "Informații Șofer",
                "name": "Nume",
                "email": "Email",
                "phone": "Telefon",
                "bank_account": "Cont Bancar / IBAN",
                "reviews_rating": "Recenzii & Rating",
                "address": "Adresă",
                "rating": "Rating",
                "status": "Status",
                "experience": "Experiență",
                "car_name": "Nume Vehicul",
                "car_number": "Număr Vehicul",
                "ride_details": "Detalii Călătorie",
                "no_driver_found": "Niciun șofer găsit",
                "reason_of_deletion": "Motivul ștergerii"
            }
        },
        "driver_request": {
            "driver_requests": "Cereri Șoferi",
            "placeholder": "Caută nume, email și număr de telefon",
            "driver_request_info": {
                "driver_request_details": "Detalii Cerere Șofer",
                "accept": "Acceptă",
                "reject": "Respinge",
                "cancel": "Anulează",
                "driver_info": "Informații Șofer",
                "name": "Nume",
                "email": "Email",
                "phone": "Telefon",
                "driver_customer": "Șofer/Client",
                "vehicle_name": "Nume Vehicul",
                "vehicle_number": "Număr Vehicul",
                "experience": "Experiență",
                "rejection": "Respins",
                "enter_reason_rejection": "Te rugăm să introduci motivul respingerii"
            }
        },
        "rides": {
            "rides": "Călătorii",
            "placeholder": "Caută după adresă",
            "ride_details": {
                "ride_on_map": "Călătorie pe Hartă",
                "ride_details": "Detalii Călătorie",
                download_invoice:"Descărcați factura"
            },
            "rating": {
                "customer": {
                    "customer_rating": "Rating Client",
                    "name": "Nume Client"
                },
                "driver": {
                    "driver_rating": "Rating Șofer",
                    "name": "Nume Șofer",
                    "review": "Recenzii"
                }
            }
        },
        "vehicle": {
            "vehicles": "Vehicule",
            "error_messages": {
                "commision_required": "Comisionul este necesar",
                "only_numeric": "Te rugăm să introduci doar valori numerice",
                "price_required": "Prețul este necesar",
                "cancelation_charge_required": "Taxa de anulare este necesară",
                "base_fee_required": "Taxa de bază este necesară"
            },
            "edit_vehicle": {
                "edit_vehicle": "Editează Vehicul",
                "commission": "Comision",
                "price_per_km": "Preț pe KM",
                "cancelation_charge": "Taxe de Anulare",
                "price_per_min": "Preț pe Minut",
                "ride_charge": "Cost Călătorie",
                "base_fare": "Tarif de Bază",
                "publish": "Publică"
            }
        },
        "deleted_customers": {
            "deleted_customers": "Clienți Șterși",
            "placeholder": "Caută client, email și număr de telefon"
        },
        "content": {
            "content": "Conținut"
        },
        "complaints": {
            "complaints": "Reclamații"
        },
        "notifications": {
            "notifications": "Notificări",
            "send_notifications_to": "Trimite notificări către",
            "all_customers": "Toți Clienții",
            "customer": "Client",
            "drivers": "Șoferi",
            "selected_customer_drivers": "Clienți/Șoferi Selectați",
            "select_notification_type": "Selectează tipul de notificare",
            "title": "Titlu",
            "message": "Mesaj",
            "please_select_user_by_email": "Te rugăm să selectezi utilizatorul după email",
            "send": "TRIMITE",
            "push": "Push",
            "email": "Email"
        },
        "contact_us": {
            "contact_us": "Contactează-ne",
            "no_content_found": "Niciun mesaj Contactează-ne găsit"
        },
        "faqs": {
            "faqs": "Întrebări Frecvente",
            "add": {
                "add": "ADĂUGAȚI",
                "add_faq": "Adaugă Întrebare Frecventă",
                "edit_faq": "Editează Întrebare Frecventă",
                "save_changes": "Salvează Modificările",
                "cancel": "Anulează",
                "delete_msg": "Ești sigur că vrei să ștergi Întrebarea Frecventă?",
                "question": "Întrebare",
                "answer": "Răspuns",
                "save": "Salvează"
            }
        }
    },
    cs: {
        "common": {
            "loading": "Načítání",
            "logout": {
                "logout_message": "Opravdu se chcete odhlásit:",
                "yes": "Ano"
            },
            "dropdown": {
                "all": "Vše",
                "block": "Blokovat",
                "unblock": "Odblokovat",
                "new": "Nový",
                "rejected": "Odmítnuto",
                "pending": "Čeká na vyřízení",
                "resolved": "Vyřešeno"
            },
            "documents": {
                "document": "Dokumenty",
                "vehicle_registration": "Registrace vozidla",
                "vehicle_insurance": "Pojištění vozidla",
                "driving_licence": "Řidičský průkaz",
                "pending_documents": "Čekající dokumenty",
                "accept": "Přijmout",
                "reject": "Odmítnout"
            },
            "footer": {
                "copyright": "Autorská práva",
                "all_rights_reserved": "Všechna práva vyhrazena."
            }
        },
        "messages": {
            "error": {
                "error": "Chyba",
                "email_not_exist": "Email neexistuje",
                "enter_title": "Zadejte prosím název",
                "enter_message": "Zadejte prosím zprávu",
                "valid_data": "Zadejte prosím platná data",
                "rejection_reason": "Zadejte prosím důvod zamítnutí",
                "provide_reason": "Zadejte prosím důvod zamítnutí"
            },
            "success": {
                "success": "Úspěch",
                "user_blocked": "Uživatel zablokován",
                "driver_blocked": "Řidič zablokován",
                "driver_accepted": "Řidič přijat",
                "driver_unblocked": "Řidič odblokován",
                "user_unblocked": "Uživatel odblokován",
                "deactivated": "Deaktivováno",
                "activated": "Úspěšně aktivováno!!!",
                "complain_resolved": "Stížnost vyřešena.",
                "complain_changed_pending": "Stížnost změněna na čekající.",
                "faq_added": "Časté dotazy úspěšně přidány",
                "faq_edited": "Časté dotazy úspěšně upraveny",
                "faq_deleted": "Časté dotazy úspěšně odstraněny",
                "driver_rejected": "Řidič zamítnut",
                "notification_sent": "Oznámení odesláno",
                "request_accepted": "Žádost úspěšně přijata",
                "request_rejected": "Žádost úspěšně zamítnuta",
                "document_accepted": "Dokument přijat.",
                "document_rejected": "Dokument zamítnut."
            },
            "warn": {
                "fill_all_field": "Vyplňte prosím všechna vstupní pole"
            }
        },
        "table_data": {
            "title": "Název",
            "content": "Obsah",
            "sr_no": "Číslo",
            "name": "Jméno",
            "email": "Email",
            "phone": "Telefon",
            "rating": "Hodnocení",
            "driver_capital": "ŘIDIČ",
            "customer_capital": "ZÁKAZNÍK",
            "driver_name": "Jméno řidiče",
            "customer_name": "Jméno zákazníka",
            "driver_rating": "Hodnocení řidiče",
            "address": "Adresa",
            "tag": "Štítek",
            "start_address": "Začáteční adresa",
            "end_address": "Konečná adresa",
            "ride_id": "ID jízdy",
            "sent_by": "Odesláno od",
            "message": "Zpráva",
            "date": "Datum",
            "time": "Čas",
            "distance": "Vzdálenost",
            "status": "Stav",
            "actions": "Akce",
            "view_capital": "ZOBRAZIT",
            "block_capital": "BLOKOVAT",
            "unblock_capital": "ODBLOKOVAT",
            "edit": "Upravit",
            "back": "Zpět",
            "delete": "Smazat",
            "no_record_found": "Žádný záznam nenalezen",
            "no_rows_found": "Žádné řádky nenalezeny",
            "no_data_found": "Data nenalezena",
            "no_complaints_found": "Žádné stížnosti nenalezeny",
            "type": "Typ",
            "base_fee": "Základní poplatek",
            "price_per_km": "Cena za km",
            "read_more": "Přečíst více",
            "price_per_min": "Cena za min",
            "completed": "Dokončeno",
            "ride_cancel_by_user": "Jízda zrušena uživatelem",
            "ride_cancel_by_driver": "Jízda zrušena řidičem",
            "no_driver_found": "Řidič nenalezen",
            "ride_expired": "Jízda vypršela",
            "date_time": "Datum a čas",
            "fair": "Spravedlivé",
            "commission": "Provize",
            "ride_charge": "Poplatek za jízdu",
            "cancelation_harge": "Poplatek za zrušení",
            "new": "Nový",
            "update": "Aktualizovat",
            "cancel": "Zrušit",
            "add_page": "Přidat stránku",
            "questions": "Otázky",
            "answers": "Odpovědi",
            "join_on": "Připojit se dne",
            cash_enable: "Povolit hotovost",
            wallet_amount: 'Částka v peněžence'
        },
        "header": {
            "admin_panel": "Administrátorský panel"
        },
        "menu_bar": {
            "dashboard": "Nástěnka",
            "customers": "Zákazníci",
            "drivers": "Řidiči",
            "driver_requests": "Žádosti o řidiče",
            "rides": "Jízdy",
            "vehicles": "Vozidla",
            "deleted_customers": "Smazaní zákazníci",
            "deleted_drivers": "Smazaní řidiči",
            "content": "Obsah",
            "complaints": "Stížnosti",
            "notifications": "Oznámení",
            "contact_us": "Kontaktujte nás",
            "faqs": "Často kladené otázky",
            "logout": "Odhlásit se"
        },
        "dashboard": {
            "customers": "Zákazníci",
            "drivers": "Řidiči",
            "driver_requested": "Požadováni řidiči",
            "rides": "Jízdy",
            "contents": "Obsah",
            "unresolved_messages": "Nevyřešené zprávy",
            "unresolved_complaints": "Nevyřešené stížnosti"
        },
        "customer": {
            "customers": "Zákazníci",
            "placeholder": "Hledat zákazníka podle emailu a telefonního čísla",
            "customer_details": {
                "customer_details": "Podrobnosti o zákazníkovi",
                "personal_info": "Osobní informace",
                "name": "Jméno",
                "email": "Email",
                "phone": "Telefon",
                "reviews_rating": "Recenze a hodnocení",
                "address": "Adresa",
                "ride_details": "Detaily jízdy",
                "reason_of_deletion": "Důvod smazání"
            }
        },
        "drivers": {
            "drivers": "Řidiči",
            "wallet": {
                "edit_wallet_amount": "Upravit částku v peněžence",
                "reason": "Důvod",
                "save": "Uložit",
                "placeholder": "Důvod",
                "decrease": "Snížit",
                "increase": "Zvýšit",
                "amount": "Částka",
                "greater_than": "Zadejte prosím hodnotu větší než",
                "less_than": "Zadejte prosím hodnotu menší než",
                "enter_amount": "Zadejte prosím částku",
                "valid_reason": "Zadejte prosím platný důvod"
            },
            "placeholder": "Hledat jméno, email a telefonní číslo",
            "driver_info": {
                "driver_infos": "Informace o řidiči",
                "driver_details": "Podrobnosti o řidiči",
                "personal_info": "Osobní informace",
                "name": "Jméno",
                "email": "Email",
                "phone": "Telefon",
                "bank_account": "Bankovní účet / IBAN",
                "reviews_rating": "Recenze a hodnocení",
                "address": "Adresa",
                "rating": "Hodnocení",
                "status": "Stav",
                "experience": "Zkušenosti",
                "car_name": "Název auta",
                "car_number": "Číslo auta",
                "ride_details": "Detaily jízdy",
                "no_driver_found": "Řidič nenalezen",
                "reason_of_deletion": "Důvod smazání"
            }
        },
        "driver_request": {
            "driver_requests": "Žádosti o řidiče",
            "placeholder": "Hledat jméno, email a telefonní číslo",
            "driver_request_info": {
                "driver_request_details": "Podrobnosti o žádosti o řidiče",
                "accept": "Přijmout",
                "reject": "Odmítnout",
                "cancel": "Zrušit",
                "driver_info": "Informace o řidiči",
                "name": "Jméno",
                "email": "Email",
                "phone": "Telefon",
                "driver_customer": "Řidič/Zákazník",
                "vehicle_name": "Název vozidla",
                "vehicle_number": "Číslo vozidla",
                "experience": "Zkušenosti",
                "rejection": "Odmítnutí",
                "enter_reason_rejection": "Zadejte důvod odmítnutí"
            }
        },
        "rides": {
            "rides": "Jízdy",
            "placeholder": "Hledat podle adresy",
            "ride_details": {
                "ride_on_map": "Jízda na mapě",
                "ride_details": "Detaily jízdy",
                download_invoice:"Stáhnout fakturu"
            },
            "rating": {
                "customer": {
                    "customer_rating": "Hodnocení zákazníka",
                    "name": "Jméno zákazníka"
                },
                "driver": {
                    "driver_rating": "Hodnocení řidiče",
                    "name": "Jméno řidiče",
                    "review": "Recenze"
                }
            }
        },
        "vehicle": {
            "vehicles": "Vozidla",
            "error_messages": {
                "commision_required": "Provize je povinná",
                "only_numeric": "Zadejte pouze číselnou hodnotu",
                "price_required": "Cena je povinná",
                "cancelation_charge_required": "Poplatek za zrušení je povinný",
                "base_fee_required": "Základní poplatek je povinný"
            },
            "edit_vehicle": {
                "edit_vehicle": "Upravit vozidlo",
                "commission": "Provize",
                "price_per_km": "Cena za km",
                "cancelation_charge": "Poplatky za zrušení",
                "price_per_min": "Cena za min",
                "ride_charge": "Poplatek za jízdu",
                "base_fare": "Základní sazba",
                "publish": "Zveřejnit"
            }
        },
        "deleted_customers": {
            "deleted_customers": "Smazaní zákazníci",
            "placeholder": "Hledat zákazníky, emaily a telefonní čísla"
        },
        "content": {
            "content": "Obsah"
        },
        "complaints": {
            "complaints": "Stížnosti"
        },
        "notifications": {
            "notifications": "Oznámení",
            "send_notifications_to": "Odeslat oznámení na",
            "all_customers": "Všechny zákazníky",
            "customer": "Zákazník",
            "drivers": "Řidiči",
            "selected_customer_drivers": "Vybraní zákazníci/řidiči",
            "select_notification_type": "Vyberte typ oznámení",
            "title": "Název",
            "message": "Zpráva",
            "please_select_user_by_email": "Vyberte uživatele podle emailu",
            "send": "Odeslat",
            "push": "Push",
            "email": "Email"
        },
        "contact_us": {
            "contact_us": "Kontaktujte nás",
            "no_content_found": "Žádná zpráva Kontaktujte nás nenalezena"
        },
        "faqs": {
            "faqs": "Často kladené otázky",
            "add": {
                "add": "Přidat",
                "add_faq": "Přidat FAQ",
                "edit_faq": "Upravit FAQ",
                "save_changes": "Uložit změny",
                "cancel": "Zrušit",
                "delete_msg": "Opravdu chcete smazat FAQ?",
                "question": "Otázka",
                "answer": "Odpověď",
                "save": "Uložit"
            }
        }
    }


} as any

